.mobile-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-popup-content {
    background-color: #f3f3f3;
    width: 100vw;
    height: 100vh;
    padding-top: 0px;
    overflow-y: auto;
    position: relative;
}
.mobile-popup .mobile-popup-content .close-btn-pop {
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #edecec;
    color: #fff;
    display: flex;
    min-height: 57px;
    padding: 0 15px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0;
    z-index: 1111;
}
.close-button-mobile-view {
    border: none;
    cursor: pointer;
    z-index:30;
    background-color: transparent;
    padding: 0;
    font-family: Montserrat,sans-serif;
    color: #666;
    font-size: 18px;
    position: relative;
    padding-left: 45px;
    font-weight: 500;
}
.close-button-mobile-view .svg-inline--fa {
    background-color: #f2f3f4;
    color: #999;
    padding: 8px;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    left: 0;
}