.table-responsive {
  min-height: .01%;
  overflow-x: visible;
}
.link-button {
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    color: blue; /* Set the link color */
    text-decoration: underline; /* Add underline for the link effect */
    cursor: pointer;
}

.link-button:focus {
    outline: none;
}

.full-width {
    width: 100% !important;
}
