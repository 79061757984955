/* Header.css */

/* Style for desktop menu buttons */
.desktop-menu-button {
  font-size: 16px !important; /* Increase font size */
  text-transform: none; /* Optional: Keep the text as is, without uppercase */
  display: flex;
  align-items: center;
}

/* Style for badges in the cart */
.badge {
  background-color: #ff4081; /* Example color */
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  margin-left: 4px;
}

/* Optional: Adjust icon spacing if using glyphicons */
.glyphicon {
  font-size: 1.2rem; /* Adjust icon size */
}

/* Adjust the brand logo spacing in desktop view */
.brand-name img {
  margin: 10px 20px 10px 0;
}

/* Optional: Additional styles for the desktop menu */
.desktop-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Example hover effect */
}
