html {
    height: 100%;
    box-sizing: border-box;
}
body {
    position: relative;
    margin: 0;
    padding-bottom: 80px;
    min-height: 100%;
}
.container {
    margin: auto;
    max-width: 100%;
    width: 1380px;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    /*padding: 0 20px;*/
    padding: 0;
    z-index: 1;
    /*box-shadow: 1px 0 20px rgba(0,0,0,.1);*/
}
.inner-header {
    position: relative;
}
.logo {
    /*flex: 1;*/
}
.logo img {
    height: 60px;
}
.login-button {
    /*flex: 1;*/
    text-align: right;
}
.feature-image {
    background: url('/public/images/slider.png') center center no-repeat;
    background-size: cover;
    height: 650px;
    position: relative;
    /*display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;*/
    text-align: center;
    color: #fff;
}
.feature-image h2 {
    font-size: 36px;
    margin:0;
    padding: 210px 0 0;
}
.feature-image h2 span {
    font-family: 'Caveat', cursive;
    font-size: 80px;
    font-weight: 500;
}
/*.feature-image-upper {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 50px;
    right: 30%;
    background: url('/public/images/slider-upper.png') center center no-repeat;
    background-size: contain;
}*/
.box {
    margin-top: 40px;
    text-align: center;
}
.box img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}
.login-button .btn-primary {
    background-color: #0049ed;
    box-shadow: transparent;
    color: #fff;
    padding: 10px 20px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    border: none;
    border-radius: 8px;
}
.form-wrap {
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    bottom: 150px;
    text-align: center;
    width: 90%;
    left: 5%;
    padding: 15px;
}
.form-inline {
    /*padding: 8px;*/
}
.form-inline .form-group {
    /*margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;*/
    border-left: 2px solid #f2f2f2;
    margin: 7px 0;
}
.form-inline .form-group.selectbox-wrap {
    border-left: 0px transparent;
}
.form-inline .form-group.selectbox-wrap .css-13cymwt-control {
    border: none;
}
.form-inline .form-group .css-1u9des2-indicatorSeparator {
    display: none !important;
}
.selectbox-wrap .form-control {
    padding: 0;
    border: 0;
}
.selectbox-wrap .fa-location-pin {
    width: 15px;
}
.selectbox-wrap .form-control .css-1xc3v61-indicatorContainer {
    padding-top: 6px;
    padding-bottom: 6px;
}
.selectbox-wrap .form-control .css-1fdsijx-ValueContainer {
    padding-bottom: 0;
    padding-top: 0;
}
.selectbox-wrap .form-control .css-1jqq78o-placeholder {
    color: #000 !important;
    font-weight: 600;
    text-align: left;
}
.selectbox-wrap .form-control .css-13cymwt-control {
    min-height: 35px;
}
.css-t3ipsp-control,
.css-15lsz6c-indicatorContainer:hover,
.css-t3ipsp-control:hover,
.css-13cymwt-control:hover,

.css-15lsz6c-indicatorContainer:visited,
.css-t3ipsp-control:visited,
.css-13cymwt-control:visited {
    border: none !important;
    box-shadow: 0 0 0 0px #fff !important;
}
.form-wo-border .form-group .form-control {
    border: 0;
    box-shadow: inset 0 0px 0px rgba(0,0,0);
    border-radius: 0;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    width: calc(100% - 30px);
    margin-bottom: 0;
}
.form-wo-border .form-group .icon {
    color: #0049ed;
    position: relative;
    top: 2px;
}
.form-wo-border .btn-primary {
    border-color: #16A34A;
    padding: 12px 0;
    text-transform: uppercase;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}
.usp-wrap {
    position: absolute;
    bottom: 45px;
    width: 90%;
    left: 5%;
}
.width-80 {
    width: 80%;
}
.margin-auto {
    margin-left: auto;
    margin-right: auto;
}
.usp-wrap ul {
    margin: 0;
    padding: 0;
}
.usp-wrap ul li {
    list-style: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    display: inline-block;
    line-height: 20px;
    padding: 0 30px;
    text-align: left;
}
.usp-wrap ul li .svg-inline--fa {
    background-color: rgba(255, 255, 255, .3);
    width: 20px;
    height: 20px;
    line-height: 60px;
    padding: 20px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-top: -10px;
}
.description-box {
    border: 1px solid #E0e0e0;
    border-radius: 10px;
    padding: 40px;
}
.description-box .icon {
    width: 80px;
    height: 80px;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.des-icon1 {
    background-image: url('/public/images/group38004.png');
}
.description-box h3 {
    color: #333;
    font-size: 16px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding: 10px 0;
    font-weight: 600;
    margin: 0;
}
.description-box p {
    color: #666;
    font-size: 16px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    padding: 0;
}
.form-wrap .invalid-feedback {
    color: #dc3545;
    left: 48px;
    bottom: -7px;
    margin: 0;
    font-size: 12px;
    right: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
}
.p-10 {
    padding: 10px;
}
.p-20 {
    padding: 20px;
}
.p-30 {
    padding: 30px;
}
.p-40 {
    padding: 40px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-40 {
    padding-top: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-40 {
    padding-right: 40px;
}


.m-10 {
    margin: 10px;
}
.m-20 {
    margin: 20px;
}
.m-30 {
    margin: 30px;
}
.m-40 {
    margin: 40px;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}

.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-40 {
    margin-left: 40px;
}

.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-40 {
    margin-right: 40px;
}

.footer {
    background-color: #333;
    box-shadow: 1px 0 20px rgba(0,0,0,.1);
    margin: 0;
    padding: 30px 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}
.footer-links {
    margin: 0;
    padding: 0;
}
.footer-links li {
    list-style: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    border-right: 1px solid rgba(255,255,255,.3);
    padding: 0 10px;
}
.footer-links li:first-child {
    padding-left: 0;
}
.footer-links li:last-child {
    border-right: none;
}
.footer-links li a {
    color: #fff;
}
.copyright {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #999;
    padding: 0 10px;
    text-align: right;
}
.form-wrap .form-inline .form-group.selectbox-wrap {
    text-align: left;
}
/*.css-1nmdiq5-menu {
    top: 47px !important;
    left: -28px;
    border-radius: 15px !important;
    min-width: 250px;
}*/

.add-plate-pop h2 {
    text-align: left;
}
.add-plate-pop .form-group {
    text-align: left;
    margin-bottom: 20px;
}
.add-plate-pop .form-group .form-control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.add-plate-pop .form-group .form-select__control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff !important;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.add-plate-pop .form-group .form-select__control:hover,
.add-plate-pop .form-group .form-select__control:focus,
.add-plate-pop .form-group .form-select__control:visited {
    border: 1px solid #ebf1ff !important;
}
.add-plate-pop .form-group .form-select__control input {
    height: auto !important;
    padding: 0 !important;
}
.header .nav.nav-pills {
    background-color: #0953fa;
    padding: 22px;
}
.header .nav.nav-pills .dropdown .dropdown-toggle {
    padding: 0;
    color: #fff;
}
.header .nav.nav-pills .dropdown .dropdown-toggle:hover,
.header .nav.nav-pills .dropdown .dropdown-toggle:visited,
.header .nav.nav-pills .dropdown .dropdown-toggle:focus,
.header .nav.nav-pills .dropdown.open > a,
.header .nav.nav-pills .dropdown.open > a:focus, 
.header .nav.nav-pills .dropdown.open > a:hover {
    background-color: transparent !important;
}
.header .nav.nav-pills .dropdown .dropdown-toggle .caret {
    border-width: 6px;
}
.header .nav.nav-pills .dropdown .dropdown-toggle .glyphicon-user {
    background-color: #0049ed;
    border-radius: 50%;
    width: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 10px;
}
.header .nav.nav-pills .dropdown .dropdown-menu {    
    right: -23px;
    left: auto;
    top: 57px;
    margin: 0;
    border-radius: 0 0 10px 10px;
    border: none;
}
.header .nav.nav-pills .dropdown .dropdown-menu li a {
    padding: 5px 10px;
}
.user-profile {
    display: flex;
    align-items: center;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    border-bottom: 1px solid #f1f1f1;
}
.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-info {
  display: flex;
  flex-direction: column;
}
.user-name {
  font-weight: 600;
  margin-bottom: 0px;
  color: #024430;
  line-height: 18px;
  font-size: .9em;
  text-transform: uppercase;
}
.user-email {
  font-size: .9em;
  color: #777;
}
.header .nav.nav-pills .dropdown-item {
  font-family: 'Open Sans', sans-serif;
  color: #024430;
  font-size: .9em;
  padding: 11px 10px 8px 10px;
  border-bottom: 1px solid #f1f1f1;
  text-transform: uppercase;
  display: block;
}
.header .nav.nav-pills .dropdown-item:last-child {
    border-bottom: none;
}
.header .nav.nav-pills .dropdown-item:hover {
    text-decoration: none;
    background-color: #f9f9f9;
}
.header .nav.nav-pills .dropdown-item .glyphicon {
    color: #ccc;
    padding-right: 5px;
}

/* StateMunicipalityDropdown.css */

/* Style for the selected state area */
.selected-state {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f4f4f4; /* Background color for the selected state area */
  border: 1px solid #ccc; /* Border for the selected state area */
  border-radius: 4px; /* Rounded corners */
  color:#333;
  font-weight: bold;
  cursor: pointer;
}
.btn-back {
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
}
.selected-state-label {
  margin-left: 5px; /* Add some margin between the "Back" button and the selected state */
  font-weight: bold;
}

.tate-section {
    color:#333 !important;
}



/* StateMunicipalityDropdown.css */

/* Style the state-section */
.state-section {
    color: #000 !important;
  /* Add your custom styles here */
}

/* Style the municipality-section */
.municipality-section {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix */
.custom-select__control {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for focused elements */
.custom-select__control--is-focused {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for selected values */
.custom-select__single-value {
    color: #fff !important;
  /* Add your custom styles here */
}

/***************** home page select state start ****************/
.form-wrap .form-inline .form-group.selectbox-wrap {
    padding-top: 0;
    padding-bottom: 0;
}
.selectbox-wrap .municipality-section {
    display: flex;
    flex-direction: row-reverse;
    align-content: flex-start;
    text-align: left;
    float: left;
    width: 100%;
    margin-top: -3px;
}
.selectbox-wrap .municipality-section .css-b62m3t-container,
.selectbox-wrap .municipality-section .selected-state {
    width: 50%;
}
.selectbox-wrap .municipality-section .custom-select__indicator {
    padding: 2px;
}
.state-section .css-b62m3t-container .custom-select__control {
    margin-top: -3px !important;
    min-height: 35px;
}
.state-section .custom-select__control .custom-select__indicators .custom-select__indicator-separator {
    display: none;
}
.state-section .form-select {
}
.state-section .form-select__control,
.selectbox-wrap .municipality-section .form-select__control {
    margin-top: 4px !important;
}
.selectbox-wrap .municipality-section .selected-state {
    margin-top: 0;
    border: none;
    border-right: 1px solid #a2b3dd;
    border-radius: 0;
    background-color: transparent;
    margin-right: 5px;
}
.selectbox-wrap .municipality-section .selected-state .icon {
    background-color: #0049ed;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    padding: 4px 5px;
    top: 0px;
}
.selectbox-wrap .municipality-section .selected-state .selected-state-label {
    box-shadow: inset 0 0px 0px rgba(0,0,0);
    border-radius: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #e1e1e1;
    font-weight: normal;
}
/***************** home page select state end ****************/
/*************** Municipality-section start ****************/

.step2 .municipality-section,
.ReactModalPortal .municipality-section {
    display: flex;
    flex-direction: column-reverse;
}
.step2 .state-section .custom-select__control,
.ReactModalPortal .state-section .custom-select__control,
.step2 .municipality-section .css-b62m3t-container > .custom-select__control,
.ReactModalPortal .municipality-section .css-b62m3t-container > .custom-select__control {
    background-color: #f3f3f3;
    border: none !important;
    box-shadow: inset 0 0 0 rgba(255,255,255,0) !important;
    border-radius: 10px;
    height: 53px;
}
.step2 .municipality-section .custom-select__control .custom-select__single-value,
.ReactModalPortal .municipality-section .custom-select__control .custom-select__single-value {
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
}
.step2 .municipality-section .custom-select__control.css-13cymwt-control .custom-select__indicators .custom-select__indicator-separator,
.ReactModalPortal .municipality-section .custom-select__control.css-13cymwt-control .custom-select__indicators .custom-select__indicator-separator {
    display: none;
}
.step2 .municipality-section .custom-select__control .custom-select__value-container .custom-select__placeholder,
.ReactModalPortal .municipality-section .custom-select__control .custom-select__value-container .custom-select__placeholder {
    font-size: 18px;
    color: #888;
    font-family: 'Inter', sans-serif;
}
.step2 .municipality-section .selected-state,
.ReactModalPortal .municipality-section .selected-state {
    margin-top: 0;
    margin-bottom: 15px;
    background-color: #f3f3f3;
    border: 1px solid #ebf1ff !important;
    box-shadow: inset 0 0 0 rgba(255,255,255,0) !important;
    border-radius: 10px;
    height: 53px;
}
.step2 .state-section .custom-select__control .custom-select__placeholder,
.ReactModalPortal .state-section .custom-select__control .custom-select__placeholder {
    font-size: 18px;
    color: #888888;
    font-family: 'Inter', sans-serif;
}
.step2 .selected-state .icon,
.ReactModalPortal .selected-state .icon {
    background-color: #0049ed;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    padding: 4px 5px;
    top: 0px;
    margin-left: 10px;
}
.step2 .selected-state .selected-state-label,
.ReactModalPortal .selected-state .selected-state-label {
    font-size: 18px;
    color: #888;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
}
/*************** Municipality-section end ****************/
/*************** tabs start *****************/
.tabber-wrap {
    border: 1px solid #dcdcdc;
    border-radius: 0 15px 15px 15px;
    width: 100%;
    margin: auto;
}
.tab-buttons .tab-button {
    display: inline-block;
    padding: 0;
    border: 1px solid #dcdcdc;
    border-bottom: none;
    width: 180px;
    height: 60px;
    border-radius: 10px 10px 0 0;
    margin-right: 10px;
    position: relative;
    background-color: #dcdcdc !important;
    text-indent: -3333px !important;
}
.tab-buttons .tab-button.ach-tab {
    background: url('/public/images/ACH-grey-big.png') center center no-repeat;
    background-size: 80px auto;
}
.tab-buttons .tab-button.card-tab {
    background: url('/public/images/Card-grey-big.png') center center no-repeat;
    background-size: 110px auto;
}
.tab-buttons .tab-button.active {
    border-color: #dcdcdc;
    position: relative;
    top: 1px;
    background-color: #fff !important;
}
.tab-buttons .tab-button.ach-tab.active {
    background: url('/public/images/ACH-current-big.png') center center no-repeat;
    background-size: 80px auto;
}
.tab-buttons .tab-button.card-tab.active {
    background: url('/public/images/Card-current.png') center center no-repeat;
    background-size: 110px auto;
}
/*************** tabs end *****************/
.clear-both {
    clear: both;
}
/* Custom styles for tabs */
.react-tabs {
    font-family: 'Inter', sans-serif;
}
/* Style tab list */
.react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: none !important;
}
/* Style individual tab */
.react-tabs__tab {
    padding: 15px 20px !important;
    cursor: pointer;
    color: #757575;
    border: 1px solid #dcdcdc !important;
    border-bottom: none;
    margin-bottom: 0;
    background-color: #dcdcdc;
    border-radius: 10px 10px 0 0 !important;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}
/* Add some spacing between tabs */
.react-tabs__tab:not(:last-child) {
    margin-right: 10px;
}

/* Change background color on hover */
.react-tabs__tab:hover {
    background-color: #ddd;
}

/* Style the active tab */
.react-tabs__tab.react-tabs__tab--selected {
    background-color: #fff;
    border-bottom: 1px solid #fff !important;
    color: #044bec;
}
.react-tabs__tab .svg-inline--fa {
    font-size: .8em;
    padding-right: 5px;
}
/* Style tab content panels */
.react-tabs__tab-panel {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}
.btn-secondary {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-secondary:hover {
  background-color: #999;
}
.form-design .form-group {
    margin-bottom: 20px;
}
.form-design .form-group label {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
}
.form-design .form-group .form-control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.form-design .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
/*********************cards start *********************/
.card-wrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    flex-direction: row;
    flex-grow: 2;
}
.card-inner {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgb(60, 72, 88, 0.4);
    width: calc(100% / 2 - 15px);
    margin-bottom: 30px;
    position: relative;
    /*padding-bottom: 60px;*/
}
.card-inner .card-header {
    border-bottom: 1px dashed #e2e8f0;
    padding: 15px;
    float: left;
    width: 100%;
}
.card-inner .card-footer {
    border-top: 1px dashed #e2e8f0;
    padding: 15px;
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.card-inner .card-header h4 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 32px;
}
.card-inner .btn-primary {
    border-color: #16A34A;
    padding: 5px 10px;
    border-radius: 5px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
}
.card-inner .btn-primary.view-all {
    border-color: #000;
    background: rgb(51,51,51);
    background: linear-gradient(343deg, rgba(51,51,51,1) 0%, rgba(51,51,51,1) 50%, rgba(0,0,0,1) 100%);
}
.card-inner .btn-primary.add-more {
    border: 1px solid rgb(22,163,74);
    background: transparent;
    color: rgb(22,163,74);
    margin-right: 7px;
}
.card-inner .card-body {
    padding: 15px;
    clear: both;
}
.card-inner .table thead th {
    background-color: #e1e1e1;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
}
.card-inner .table tbody td {
    border-top: 1px dashed #e2e8f0;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}
.analyse-box {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgb(60, 72, 88, 0.4);
    padding: 20px;
    position: relative;
    height: 100px;
}
.analyse-box .heading {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    color: #ccc;
    padding-bottom: 5px;
    font-weight: 700;
}
.analyse-box .value {
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    text-align: left;
    color: #333;
    font-weight: 700;
}
.analyse-box .glyphicon {
    position: absolute;
    font-size: 30px;
    color: #666;
    top: 20px;
    right: 20px;
}
/*********************cards end *********************/
/********************* menu link start ******************/
.menu-links {
    padding-left: 20px;
    flex: 1;
}
.menu-links .nav li {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}
.menu-links .nav li a {
    color: #fff;
    padding: 30px 15px;
    background-color: transparent;
}
.menu-links .nav li a .caret {
    border-width: 6px;
    margin-left: 5px;
}
.menu-links .nav li a .glyphicon {
    margin-right: 3px;
    font-size: 15px;
}
.menu-links .nav li a:hover,
.menu-links .nav li a:focus,
.menu-links .nav li a:visited  {
    background-color: transparent;
}
.menu-links .nav li.dropdown .dropdown-menu {
    border: none;
}
.menu-links .nav li.dropdown .dropdown-menu li a {
    padding: 5px 10px;
    color: #333;
    font-size: 14px;
}
.account-page {
    background-color: #f3f4f6;
}
/********************* menu link end ******************/
/********************* Breadcrumb Start********************/
.breadcrumb-wrap {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #666;
    font-weight: 400;
    padding: 0;
    margin: 0;
}
.breadcrumb-wrap li {
    list-style: none;
    display: inline-block;
}
.breadcrumb-wrap li a {
    color: #666;
}
.breadcrumb-wrap li.active {
    color: #0953fa;
    font-weight: 600;
}
.breadcrumb-wrap li:after {
    content: "/";
    color: #ccc;
    padding: 0 10px;
}
.breadcrumb-wrap li:last-child:after {
    content: "";
}
/********************* Breadcrumb end********************/
/********************* reset password start **********************/
.normal-form .form-group {
    text-align: left;
    margin-bottom: 20px;
}
.normal-form .form-group .input-group {
    display: block;
    position: relative;
}
.normal-form .form-group .form-control {
    padding: 8px 10px 8px 50px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
    z-index: 1;
}
.normal-form .form-group .form-select__control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff !important;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
    z-index: 1;
}
.normal-form .form-group .form-select__control:hover,
.normal-form .form-group .form-select__control:focus,
.normal-form .form-group .form-select__control:visited {
    border: 1px solid #ebf1ff !important;
}
.normal-form .form-group .form-select__control input {
    height: auto !important;
    padding: 0 !important;
}
.normal-form .form-group .input-group .input-group-addon {
    position: absolute;
    z-index: 2;
    background: transparent;
    border: none;
    left: 5px;
    top: 12px;
    color: #ccc;
    font-size: 20px;
}
.normal-form .form-group .help-block {
    clear: both;
    top: -10px;
    position: relative;
    color: red;
}
.normal-form .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
/********************* reset password end **********************/
.ReactModalPortal {
    z-index: 30;
    position: relative;
}
/***************** slider start ********************/
.left.carousel-control,
.right.carousel-control {
    background: transparent;
    width: 10%;
}
.left.carousel-control .control-icon {
    position: absolute;
    top: 50%;
    width: 31px;
    height: 29px;
    left: calc(50% - 15px);
    margin-top: -10px;
    background: url('/public/images/indicator-left.png') center center no-repeat;
}
.right.carousel-control .control-icon {
    position: absolute;
    top: 50%;
    width: 31px;
    height: 29px;
    right: calc(50% - 15px);
    margin-top: -10px;
    background: url('/public/images/indicator-right.png') center center no-repeat;
}
.carousel-caption {
    left: 10%;
    top: 20%;
    bottom: auto;
    right: auto;
    padding: 0;
    text-align: left;
}
.carousel-caption h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    text-align: left;
    text-shadow: none;
    font-weight: 400;
    margin: 0 0 20px;
}
.carousel-caption h1 > span {
    font-size: 84px;
    display: block;
}
.carousel-caption a.btn-primary {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
    border-radius: 0;
    border: none;
    padding: 10px 55px 10px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    transition: transform .8s; /* Animation */
}
.carousel-caption a.btn-primary:hover {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(34,196,94,1) 0%, rgba(22,164,74,1) 100%);
}
.carousel-caption a.btn-primary:after {
    content: "";
    background-image: url('/public/images/indicator-right.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 15px;
    top: calc(50% - 13px);
}
.carousel-inner > .item {
    background-color: #0b49d9;
    text-align: right;
}
.carousel-inner > .item img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
}
/***************** slider end ********************/
/***************** Home Form start****************/
.renewal-form-wrap {
    background-color: #f1f2f2;
    position: relative;
    /*height: 340px;*/
    padding: 40px 0;
}
.renewal-form-wrap.form-position {
    height: 340px;
}
.renewal-form-inner {
    background-color: #fff;
    border-radius: 30px;
    padding: 50px;
    border: 2px solid #e6e7e8;
    width: 90%;
    margin: auto;
    /*position: absolute;
    left: 10%;
    top: -40px;*/
}
.renewal-form-wrap.form-position .renewal-form-inner {
    position: absolute;
    left: 10%;
    top: -40px;   
    width: 80%;
}
.renewal-form-inner .form-wrap,
.renewal-form-inner .usp-wrap {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
}
.renewal-form-wrap.form-position .renewal-form-inner .usp-wrap {
    margin: 50px auto 0;
    width: 90%;
}
.renewal-form-inner .form-wrap {
    background-color: #0348ed;
    width: 100%;
    border-radius: 15px;
    /*border: 1px solid #c7cfe1;*/
    box-shadow: 5px 5px 9px rgba(0,0,0,.2);
}
.renewal-form-inner .form-wrap .form-inline .form-group {
    margin: 0 !important;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .css-13cymwt-control,
.renewal-form-inner .form-wo-border .form-group .form-control {
    background-color: transparent;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
}
.renewal-form-inner .form-wo-border .form-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .form-control:-ms-input-placeholder {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .form-control::-webkit-input-placeholder {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .icon {
    color: #fff;
}
.renewal-form-inner .form-wo-border .btn-primary {
    background:#ffffff url('/public/images/submit-icon.png') no-repeat;
    background-position: right 7px top 10px;
    background-size: 24px;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #16a34a;
    padding: 4px 35px 4px 10px !important;
    width: auto;
    font-size: 15px;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    border: none;
}
.renewal-form-inner .form-inline .form-group {
    border-left: 1px solid #a2b3dd;
}
.renewal-form-inner .form-inline .form-group:first-child {
    border-left: none;
}
.renewal-form-inner .state-section  .custom-select__control {
    background-color: transparent;
}
.renewal-form-inner .form-inline .custom-select__indicators {
    height: auto;
}
.renewal-form-inner .form-inline .custom-select__indicator {
    padding: 3px !important;
}
.renewal-form-inner .form-inline .custom-select__indicator svg {
    color: #fff;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control.custom-select__control--menu-is-open,
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control.custom-select__control--is-focused {
    background-color: transparent !important;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
    color: #fff !important;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__input-container {
    margin: 0px;
    margin-top: 7px;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
    margin-top: 15px;
    background-color: #0348ed;
    border-radius: 0 0 10px 10px;
}
.renewal-form-inner .usp-wrap {
    width: 90%;
    margin: 20px auto;
}
.renewal-form-inner .usp-wrap ul li {
    width: 33.33%;
    text-align: center;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #000;
    line-height: 30px;
}
.renewal-form-inner .usp-wrap ul li .svg-inline--fa {
    float: none !important;
    display: block !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    background-color: #939598;
    color: #fff;
}
.renewal-form-inner .usp-wrap ul li:nth-child(even) .svg-inline--fa {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
}
/***************** Home Form end****************/
/***************** Home marketing container start****************/
.mkt-wrap h2 {
    font-family: "Roboto", sans-serif;
    color: #0049ed;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    line-height: 36px;
}
.mkt-wrap h2 > span {
    font-size: 18px;
    color: #76777a;
    display: block;
    font-weight: 400;
}
.mkt-wrap .description-box {
    border: 2px solid #231f20;
    padding: 30px 40px 15px;
    position: relative;
    transition: transform .4s; /* Animation */
}
.mkt-wrap .description-box:hover {
    border-color: #0348ed;
    background-color: #0348ed;transform: scale(1.2);
    z-index: 444;
}
.mkt-wrap .description-box .icon {
    position: absolute;
    top: -30px;
    z-index: 1;
    background-color: #939598;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    border: 2px solid #939598;
}
.mkt-wrap .description-box:hover .icon {
    background-color: #0348ed;
    border-color: #fff;
}
.mkt-wrap .description-box .icon.des-icon1 {
    background-image: url('/public/images/mkt1.png');
    background-size: 40px auto;
}
.mkt-wrap .description-box .icon.des-icon2 {
    background-image: url('/public/images/mkt2.png');
    background-size: 40px auto;
}
.mkt-wrap .description-box .icon.des-icon3 {
    background-image: url('/public/images/mkt3.png');
    background-size: auto 40px;
}
.mkt-wrap .description-box h3 {
    font-family: "Roboto", sans-serif;
    color: #1c2733;
    font-size: 22px;
    font-weight: 400;
}
.mkt-wrap .description-box:hover h3 {
    color: #fff;
}
.mkt-wrap .description-box p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}
.mkt-wrap .description-box:hover p {
    color: #fff;
}
/***************** Home marketing container end****************/

.addMoreButtonContainer {
    margin-bottom: 15px;
    float:right;
}
.text-danger {
    color: #dc3545;
    font-family: 'Poppins', sans-serif;
}
.loggedin-bg {
    background-color: #0348ed;
}
.renewal-form-wrap .custom-select__menu-list > div {
    background-color: transparent;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.custom-select__menu .custom-select__menu-list .custom-select__option:hover {
    background: #0c4adb !important;
}


/*************** react select step2 start ************/
.state-section .custom-select__menu-list > div {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
}
.state-section .custom-select__menu .custom-select__menu-list .custom-select__option:hover {
    background: #0c4adb !important;
    color: #fff;
}
.municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option,
.step2-wrap #plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option,
.select-drop-wrap .form-select__menu .form-select__menu-list .form-select__option,
#plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option,
#preferredMethodInput .form-select__menu .form-select__menu-list .form-select__option,
#stateSelect .form-select__menu .form-select__menu-list .form-select__option {
    color: #000;
}
.municipality-section .custom-select__menu-list > div,
.step2-wrap #plateTypeSelect .form-select__menu-list > div,
.select-drop-wrap .form-select__menu-list > div,
#plateTypeSelect .form-select__menu-list > div,
#preferredMethodInput .form-select__menu-list > div,
#stateSelect .form-select__menu-list > div {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
}
.municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option:hover,
.step2-wrap #plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option:hover,
.select-drop-wrap .form-select__menu .form-select__menu-list .form-select__option:hover,
#plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option:hover,
#preferredMethodInput .form-select__menu .form-select__menu-list .form-select__option:hover,
#stateSelect .form-select__menu .form-select__menu-list .form-select__option:hover {
    background: #0c4adb !important;
    color: #fff;
}
.renewal-form-wrap .municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option {
    color: #fff;
}
.municipality-section .custom-select__single-value {
    color: #333 !important;
}
.renewal-form-wrap .municipality-section .custom-select__single-value {
    color: #fff !important;
}
/*************** react select step2 end ************/
/*************** statc pages start *****************/
.static-page-wrap {
    position: relative;
}
.static-page-wrap .wrap-img {
    background: url('/public/images/step2-back.jpg') no-repeat;
    background-size: cover;
    min-height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-position: top -80px left 0;
}
.static-page-wrap .inner-wrapper {
    padding-top: 120px;
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
}
.static-page-wrap .inner-wrapper .white-wrapper {
    margin: 0;
    background-color: #fff;
    border-radius: 30px;
    padding: 50px;
    min-height: 500px;
    border: 2px solid #e6e7e8;
}
.static-page-wrap .inner-wrapper .white-wrapper h1,
.static-page-wrap .inner-wrapper .white-wrapper h2,
.static-page-wrap .inner-wrapper .white-wrapper h3,
.static-page-wrap .inner-wrapper .white-wrapper h4,
.static-page-wrap .inner-wrapper .white-wrapper h5 {
    font-family: 'Poppins', sans-serif;
    margin: 0 0 10px 0;
    color: #333;
    padding-top: 10px;
}
.static-page-wrap .inner-wrapper h1 {
    font-size: 48px;
}
.static-page-wrap .inner-wrapper h2 {
    font-size: 30px;
}
.static-page-wrap .inner-wrapper h3 {
    font-size: 24px;
}
.static-page-wrap .inner-wrapper h4 {
    font-size: 20px;
}
.static-page-wrap .inner-wrapper h5 {
    font-size: 14px;
}
.static-page-wrap .inner-wrapper .white-wrapper p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 0 0 20px 0;
    color: #666;
}
.static-page-wrap .page-head {
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}
.static-page-wrap .inner-wrapper .white-wrapper {
    margin-top: 40px;
}
.static-page-wrap .page-head p {
    font-size: 18px;
    line-height: 30px;
    color: rgba(255,255,255,.7);
    font-weight: 600;
}
/*************** statc pages end *****************/
/*************** pricing page start ****************/
.price-wrapper {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 30px 5px;
    min-height: 550px;
    border: 2px solid #e6e7e8;
    position: relative;
}
.price-wrapper .plan-name {
    background-color: rgb(22,164,74,.1);
    border-radius: 20px;
    color: rgb(22,164,74);
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 700;
    display: inline-block;
    margin: auto;
    letter-spacing: 1px;
}
.price-wrapper h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px !important;
    text-transform: uppercase;
    padding: 0;
    font-weight: 600;
    color: #000;
    text-align: center;
}
.price-wrapper .price {
    font-family: 'Inter', sans-serif;
    font-size: 40px !important;
    padding: 0;
    font-weight: 700;
    color: #000;
    text-align: center;
}
.price-wrapper .price span {
    font-size: 16px !important;
    color: #999;
    font-weight: 600;
}
.price-wrapper .btn-primary {    
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
    border-radius: 0;
    border: none;
    padding: 10px 55px 10px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    transition: transform .8s; /* Animation */
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 40px;
    width: calc(100% - 60px);
}
.price-wrapper .btn-primary:hover {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(34,196,94,1) 0%, rgba(22,164,74,1) 100%);
}
.price-wrapper .btn-primary:after {
    content: "";
    background-image: url('/public/images/indicator-right.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 15px;
    top: calc(50% - 13px);
}
.price-wrapper ul {
    padding: 30px 0;
    margin: 0;
}
.price-wrapper ul li {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    list-style: none;
    padding: 8px 0;
}
.price-wrapper ul li .glyphicon {
    color: rgb(22,164,74);
    font-size: 14px;
    padding-right: 5px;
}
/*************** pricing page end ****************/
.front-color li a {
    color: #0049ed !important;
}
.contact-form {
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
}
.contact-info h4 {
    color: #0049ed !important;
}
.ReactModal__Content .login-content {
    max-height: 600px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 2px;
}
.loggedin-bg .menu-links {
    flex: inherit;
}
.loggedin-bg .login-button {
    flex: 1;
}

.coming-soon {
    color: #777 !important; 
    font-weight: bold;
}

.coming-soon::after {
    content: " - Coming Soon";
    color: #d9534f;
    margin-left: 5px;
}

.contactus_text a , .contactus_text svg  {
    font-size:  1.5em;
    padding-right: 5px;
}


/********** property detail page start **********/
.year-detail .container,
.property-detail-head-inner .container,
.bill-detail-wrap .container {
    padding: 0;
}
.property-detail-head-inner .container {
    z-index: 1;
    position: relative;
    display: inline-block;
    width: auto;
}
.property-detail-wrap .page-head h1 {
    text-align: left;
}
.property-detail-wrap .page-head p {
    text-align: left;
}
.property-detail-wrap .page-head ul {
    margin: 83px 0 0;
    padding: 0;
}
.property-detail-wrap .page-head ul li {
    list-style: none;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
}
.property-detail-wrap .page-head ul li span {
    margin-right: 5px;
}
.property-detail-wrap .white-wrapper .tab-list,
.bill-detail-wrap .white-wrapper .tab-list {
    text-align: center;
}
.property-detail-wrap .white-wrapper .tab-list ul,
.bill-detail-wrap .white-wrapper .tab-list ul {
    padding: 0;
    border: 2px solid #0049ed;
    display: inline-block;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 30px;
}
.property-detail-wrap .white-wrapper .tab-list ul li,
.bill-detail-wrap .white-wrapper .tab-list ul li {
    list-style: none;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    min-width: 150px;
    text-align: center;
}
.property-detail-wrap .white-wrapper .tab-list ul li a,
.bill-detail-wrap .white-wrapper .tab-list ul li a {
    padding: 10px 0;
    display: block;
    background-color: #fff;
    color: #0049ed;
    border-right: 2px solid #0049ed;
    cursor: pointer;
}
.property-detail-wrap .white-wrapper .tab-list ul li a:hover,
.bill-detail-wrap .white-wrapper .tab-list ul li a:hover {
    text-decoration: none;
}
.property-detail-wrap .white-wrapper .tab-list ul li a.active,
.bill-detail-wrap .white-wrapper .tab-list ul li a.active {
    background-color: #0049ed;
    color: #fff;
}
.property-detail-wrap .white-wrapper .tab-list ul li:first-child a,
.bill-detail-wrap .white-wrapper .tab-list ul li:first-child a {
    border-radius: 30px 0 0 30px;
}
.property-detail-wrap .white-wrapper .tab-list ul li:last-child a,
.bill-detail-wrap .white-wrapper .tab-list ul li:last-child a {
    border-radius: 0 30px 30px 0;
    border-right: 0;
}
.table-striped-columns thead {
    background-color: rgba(0,73,237,.07);
}
.table-striped-columns > :not(caption) > tr > :nth-child(2n) {
    background-color: rgba(0,73,237,.03);
}
.table-striped-columns thead tr th {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    border: none;
}
.table-striped-columns tbody tr td {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 15px;
    border-color: rgba(0,73,237,.1);
}
.table-striped-columns tbody tr:last-child td {
    border-bottom: 1px solid rgba(0,73,237,.1);   
}
.table-striped-columns > tfoot {
    background-color: rgba(0,73,237,.07);   
    border-top: 1px solid rgba(0,73,237,.1);
    border-bottom: 1px solid rgba(0,73,237,.1);
}
.table-striped-columns > tfoot > tr > :nth-child(2n) {
    background-color: transparent;
}
.table-striped-columns > tfoot > tr td {
    font-weight: bold;
    padding: 15px;
}
.table-striped-columns > tfoot > tr td.add-cart button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}
.table-striped-columns > tfoot > tr td.add-cart button .svg-inline--fa {
    margin-left: 5px;
    font-size: 20px;
}
.table-striped-columns .action-link {
    padding: 13px 15px;
}
.table-striped-columns .action-link a {
    display: inline-block;
    margin: 0 5px;
    color: #666;
    font-size: 16px;
}
.table-striped-columns .action-link a:hover {
    color: #0049ed;
}
.table-striped-columns button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 10px;
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.table-striped-columns button:first-child {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
}
.table-striped-columns button .svg-inline--fa {
    font-size: 20px;
    color: #333;
}
.table-striped-columns button.remove-cart .svg-inline--fa {
    color: #ccc;
}
.property-detail-wrap .white-wrapper h2 {
    font-size: 24px;
    padding-bottom: 20px;
    font-weight: 600;
    color: #333;
}
.bill-detail-wrap h2 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #333;
}
.bill-detail-wrap .parcel-wrap .box-wrap {
    background-color: rgba(255,255,255,.8);
    padding: 10px 15px;
    margin-bottom: 1px;
}
.bill-detail-wrap .parcel-wrap {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #666;
    font-weight: 500;
}
.bill-detail-wrap .parcel-wrap span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding-top: 3px;
}
.cart-link a {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0 15px;
    color: #fff;
    line-height: 40px;
    display: inline-block;
    text-decoration: none;
    position: relative;
}
.cart-link a:hover {
    text-decoration: none;
}
.cart-link a span.glyphicon {
    font-size: 20px;
    margin-left: 3px;
    position: relative;
    top: 3px;
}
.cart-link a span.badge {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    color: #fff;
    background-color: red;
    border-radius: 50%;
    font-size: 12px;
    width: 15px;
    line-height: 15px;
    display: inline-block;
    padding: 0;
    top: 4px;
    right: 7px;
}
.search-wrap {
    min-width: 400px;
    display: inline-block;
    margin-right: 10px;
}
.renewal-form-inner .form-wo-border .btn-primary.search-btn {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    position: relative;
    top: -1px;
}
.property-detail-head {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 102;
    border-bottom: 1px solid rgba(255,255,255,.2);
    /*background-color: rgba(0,0,0,.5);*/
}
.property-detail-head.scrolled {
    background-color: rgba(7,43,123,.9);
}
.property-detail-head .logo {
    filter: brightness(0) invert(1);
}
.property-detail-head .front-color li a {
    color: #fff !important;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.property-detail-head-inner {
    position: relative;
    /*background: url('/public/images/5.jpg') center center no-repeat;*/
    background: url('/public/images/step2-back.jpg') center center no-repeat;
    background-size: cover;
    /*padding-top: 150px;*/
    padding: 130px 0 50px;
    text-align: center;
}
.property-detail-head-inner .dark {
    /*background-color: rgba(0,0,0,.7);*/
    background-color: rgba(0,0,0,.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}
.property-detail-head-inner h1 {
    margin: 0;
    padding: 0 0 10px 0;
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    color: #fff;
    z-index: 1;
    position: relative;
    font-weight: 900;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.4);
}
.property-detail-head-inner h2 {
    margin: 10px 0 0;
    padding: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    color: rgba(255,255,255,1);
    z-index: 1;
    position: relative;
    font-weight: 500;
    text-align: center;
}
button.ptn-bill {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #333;
    font-weight: 500;
    background-color: transparent;
    border: none;
    float: right;
    padding: 12px 0;
}
button.ptn-bill .glyphicon {
    margin-left: 5px;
}
.year-detail {
    background-color: #0947d6;
    padding: 10px 0;
}
.year-detail .tax-year {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    line-height: 30px;
}
.year-detail ul {
    margin: 0;
    padding: 0;
}
.year-detail ul li {
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 30px;
}
.year-detail ul li span {
    margin-left: 5px;
}
.bill-detail-wrap {
    background-color: #f1f2f2;
}
.bill-detail-wrap .white-wrapper {
    background-color: #fff;
}
.left-margin-move {
    margin-left: -30px;
}
/********** property detail page end **********/

.bgtransparent {
    display: block !important;
    background-color: rgba(0,0,0,.5);
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
}
.home-search {
    padding: 170px 0 80px;
}
.home-search h2 {
    border: none;
    font-weight: 500;
    padding-bottom: 20px;
    font-size: 24px;
}
.home-search .search-wrap {
    margin-right: 0;
    min-width: 700px;
}
.home-search .search-wrap .css-13cymwt-control,
.home-search .search-wrap .css-t3ipsp-control {
    border-radius: 50px;
    border: none;
}
.home-search .search-wrap .css-13cymwt-control .css-1fdsijx-ValueContainer,
.home-search .search-wrap .css-t3ipsp-control .css-1fdsijx-ValueContainer {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    padding: 10px 30px;
    text-align: left;
}
.home-search .search-wrap .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer,
.home-search .search-wrap .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer {
    padding: 0;
}
.home-search .search-wrap .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator,
.home-search .search-wrap .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-1u9des2-indicatorSeparator {
    margin: 0;
    background-color: transparent;
}
.home-search .search-wrap .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer,
.home-search .search-wrap .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer .css-15lsz6c-indicatorContainer {
    padding: 20px;
}
.home-search .search-wrap.css-b62m3t-container .css-1nmdiq5-menu {
    border-radius: 30px;
    padding: 20px 10px;
    text-align: left;
}
.home-search .search-wrap.css-b62m3t-container .css-1nmdiq5-menu {
}
.content-popup .close-link {
    background-color: #000;
    position: absolute;
    left: 50%;
    top: -25px;
    width: 50px;
    color: #fff;
    border-radius: 50%;
    border: none;
    height: 50px;
}
.content-popup .close-link .svg-inline--fa {
    font-size: 30px;
}
.content-popup {
    box-shadow: -3px -3px 11px rgba(0,0,0,.1);
    position: fixed;
    z-index: 1111;
    bottom: 0;
    left: 0;
    right: 0;
    top: 370px;
    background-color: #fff;
    padding: 0;
}
.content-popup h2 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #333;
}
.content-popup .white-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.padding-zero {
    padding: 0;
}
.payment-wrapper {
    /*background-color: rgba(0,0,0,.05);
    background-color: #edf2fe;*/
    background-color: #fff;
}
.cart-payment-wrap {
    /*background-color: #fff;*/
    padding-top: 15px;
    padding-bottom: 15px;
}
.cart-payment-wrap .address-wrap {
    padding: 30px 15px;
    border: none;
}
.cart-payment-wrap .address-wrap h3,
.cart-payment-wrap h3,
.cart-total-wrap h3 {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin: 10px 0 20px;
}
.cart-payment-wrap .form-group,
.form-cart .form-group {
    margin-bottom: 20px;
}
.cart-payment-wrap .address-wrap .form-group label,
.cart-payment-wrap .form-group label,
.form-cart .form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #333;
}
.cart-payment-wrap .address-wrap .form-group .form-control,
.cart-payment-wrap .form-group .form-control,
.form-cart .form-group .form-control {
    border-radius: 10px;
    height: 45px;
    box-shadow: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
}
.cart-payment-wrap .address-wrap .form-group .form-text,
.cart-payment-wrap .form-group .form-text,
.form-cart .form-group .form-text {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    display: inline-block;
}
.cart-payment-wrap .payment-wrap .form-group .alert-warning label,
.cart-payment-wrap .payment-wrap .form-group label .terms,
.cart-payment-wrap .form-group .alert-warning label,
.cart-payment-wrap .form-group label .terms  {
    font-size: 12px;
    font-weight: 400;
}
.cart-payment-wrap .payment-wrap .form-group .checkbox {
    margin: 0;
}
.cart-payment-wrap .payment-wrap ul {
    margin: 30px 20px 30px 0;
}
.cart-total-wrap {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #edf2fe;
}
.cart-total-wrap .total-amt {
    margin-bottom: 20px;
}
.cart-total-wrap .total-amt tr td {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
    color: #333;
}
.cart-total-wrap .total-amt tr td span {
    display: block;
    font-size: 13px;
    color: #666;
}
.cart-total-wrap .total-amt tr.total {
    border-top: 1px solid rgba(0,73,237,.2);
    border-bottom: 1px solid rgba(0,73,237,.2);
}
.cart-total-wrap .total-amt tr.total td {
    font-size: 24px;
}
.cart-total-wrap .emi-opt {
    margin-bottom: 20px;
}
.cart-total-wrap .emi-opt label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    color: #333;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    margin: 0 5px 0 0;
}
.cart-total-wrap .emi-opt label input[type="radio"] {
    margin-right: 5px;
}
.cart-total-wrap .alert-warning label,
.cart-total-wrap .termncondition label {
    font-weight: 400;
    font-size: 13px;
    color: #333;
}
.cart-total-wrap .submit-wrap .btn-primary {
    background-color: #0049ed;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0;
    border-radius: 50px;
    margin-top: 30px;
}
.cart-total-wrap .submit-wrap .btn-primary .svg-inline--fa {
    margin-left: 5px;
}
.payment-sch-wrap {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #ccc;
}
.payment-sch-wrap .emi-opt label {
    display: block;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.payment-sch-wrap .emi-opt label input[type="radio"] {
    margin-right: 5px;
}
#name_help {
    line-height: 18px;
    padding-top: 5px;
}
.cart-total-wrap .payment-wrap ul {
    margin-bottom: 30px;
}
.payment-wrap ul li {
    background-color: #fff !important;
}
.payment-schedule {
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
    min-height: 270px;
}
.payment-schedule h4 {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.payment-schedule ul {
    margin: 0;
    padding: 0;
}
.payment-schedule ul li {
    list-style: none;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.payment-schedule ul li .svg-inline--fa {
    color: #0e40b3;
    font-size: 11px;
    margin-right: 5px;
    font-weight: bold;
}
.google-map-container {
    height: 300px;
}
.google-map-container > div {
    height: 300px !important;
}
.paid-icon {
    font-size: 20px;
    color: #e1e1e1;
}
.pending-bill {
    color: red; /* Example style, change as needed */
    font-weight: bold;
}
.alreadt-paid {
    color: #888;
}
.table-responsiveness {
    width: 100%;
    overflow-x: auto;
}
/*********** Date picker start **********/
.react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__month-wrapper .react-datepicker__month-text {
    font-size: 13px;
    padding: 5px 20px;
    width: 80px;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__month-wrapper .react-datepicker__month-text.react-datepicker__month-text--selected:hover {
    color: #fff;
}
/*********** Date picker end **********/

.swal2-container {
    z-index: 1111;
}
.dull-box {
    background-color: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 111;
}
.hideclass {
    display:none;
}
.cart-login-link {
    margin-top: -40px;
}
.logged-in-wrap .logged-in-info {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 15px;
    float: left;
    width: 100%;
    clear: right;
}
.logged-in-wrap .logged-in-info p {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #999;
    line-height: 34px;
    float: left;
}
.logged-in-wrap .logged-in-info p .user-name {
    font-size: 1.3em;
    font-weight: 400;
    padding-top: 5px;
    color: #666;
}
.logged-in-wrap .logged-in-info .logout-button {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    background-color: #337ab7;
    border: 1px solid #2e6da4;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    float: right;
}
.logged-in-wrap .logged-in-info .logout-button:hover {
    text-decoration: none;
}
.add-plate-pop .bill-detail-wrap {
    background-color: transparent;
    padding: 0;
}
.pop-btn.action-btn {
    color: #337ab7;
}
.pop-btn.action-btn .svg-inline--fa {
    height: 15px;
    color: #337ab7;
    padding-left: 2px;
}
.swal2-container .swal2-close {
    background: none;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    padding: 0;
    background-color: #000;
    border-radius: 30px;
    width: 38px;
    line-height: 30px;
    height: 38px;
    color: #fff;
    font-size: 22px;
    border: 4px solid #fff;
}
/********** header design start ***********/
.property-detail-head .header-wrap .MuiToolbar-root {
    display: flex;
    flex-direction: row;
    /*padding: 5px 20px;*/
    padding: 0;
}
.property-detail-head .header-wrap .menu-icon {
    color: #fff;
    margin: 0px;
    padding: 20px;
    margin-right: 10px;
    background-color: rgba(0,0,0,.1);
    border-radius: 0;
}
.property-detail-head .header-wrap .menu-icon svg {
    width: 40px;
    height: 40px;
}
.property-detail-head .header-wrap .brand-name {
    filter: brightness(0) invert(1);
    margin: 5px 0;
}
.property-detail-head .header-wrap .cart-menu {
    width: auto;
    background-color: rgba(255,255,255,.1);
    border-radius: 20px;
    margin: 0 15px;
    border: 1px solid rgba(255,255,255,.3);
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    padding-right: 15px;
    position: relative;
    line-height: 20px;
}
.property-detail-head .header-wrap .cart-menu .glyphicon {
    background-color: rgba(255,255,255,.1);
    border-radius: 50px;
    padding: 10px;
    font-size: 16px;
    margin-right: 7px;  
}
.property-detail-head .header-wrap .cart-menu .badge {
    position: absolute;
    top: -7px;
    right: -7px;
    background-color: red;
}
.drawer-wrap .drawer-menu .drawer-menu-link {
    font-size: 16px;
    border-bottom: 1px solid #f9f9f9;
    padding: 15px;
    text-decoration: none;
}
.drawer-wrap .drawer-menu .drawer-menu-link:hover {
    text-decoration: none;
}
.drawer-wrap .drawer-menu .drawer-menu-link .glyphicon {
    margin-right: 5px;
    font-size: 16px;
    color: #666;
}
.drawer-wrap .drawer-menu .drawer-menu-link .MuiListItemText-primary {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #666;
}
.close-drawer {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: transparent !important;
    border-top: 2px solid #f1f1f1 !important;
    padding: 15px !important;
    border-radius: 0  !important;
}
.close-drawer svg {
    width: 35px;
    height: 35px;
}
/********** header design end ***********/
/* App.css */
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.header-mobile, .footer-mobile {
    flex-shrink: 0;
    z-index: 1;
}
.header-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--header-height);
    background-color: var(--primary-color);
    z-index: 2;
}
.footer-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: var(--footer-height);
    background-color: var(--primary-color);
    z-index: 2;
}
.content {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--background-color);
    margin-top: var(--header-height);
    margin-bottom: 60px;
}
/****************** login landing start ******************/
.login-landing {
    /*background-color: #f1f2f2;*/
    position: relative;
    padding: 65px 0 0;
}
.login-landing-wrap {
    /*background-color: #fff;*/
    border-radius: 30px;
    padding: 40px;
    background-color: #f1f2f2;
    
}
.login-landing-wrap .login-input {
    position: relative;
}
.login-landing-wrap .login-input .svg-inline--fa {
    position: absolute;
    font-size: 14px;
    color: #999;
    top: 20px;
    left: 15px;
}
.login-landing-wrap .login-input input {
    padding: 8px 10px 8px 40px!important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    /*background-color: #f3f3f3;*/
    background-color: #fff;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 20px;
}
.login-landing-wrap .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
.login-landing .register-links {
    margin: 0;
    padding: 0;
}
.login-landing .register-links li {
    list-style: none;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 20px;
    margin-left: 40px;
    position: relative;
}
.login-landing .register-links li:last-child {
    border-top: none;
}

.login-landing .register-links li:hover {
    background-color: #f3f3f3;
}
.login-landing .register-links li:hover:after {
    content: "";
    position: absolute;
    top: calc(50% - 10px );
    right: 20px;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent; 
    border-bottom: 10px solid transparent; /* 40px height (20+20) */
    border-left: 13px solid #ccc;
}
.login-landing .register-links li button {
    padding: 0;
    border: 0;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    text-align: left;
    line-height: 35px;
}
.login-landing .register-links li button span {
    display: block;
    color: #999;
    font-size: 16px;
}
.login-landing .register-links li button:hover {
    text-decoration: none;
    color: #333;
}
/****************** login landing end ******************/
.bottom-navigation {
    /*box-shadow: 0px 0px 6px rgba(0,0,0,.1);*/
}
.desktop-view {
    display: block;
}
.mobile-view {
    display: none;
}
.padding-top-zero {
    padding-top: 0 !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 15px;
    }
    .carousel-caption h1 {
        font-size: 30px;
    }
    .carousel-caption h1 > span {
        font-size: 50px;
    }
    .renewal-form-inner {
        width: 90%;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .container {
        width: 100%
    }
    .property-detail-head-inner {
        background-position: bottom center;
    }
    .year-detail .container,
    .property-detail-head-inner .container,
    .bill-detail-wrap .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .header .nav.nav-pills {
        padding: 22px 15px;
    }
    .cart-link a {
        padding: 0 20px 0 0;
    }
}
@media (min-width: 768px) and (max-width: 991px) { 
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 0;
    }
    .form-wrap .row {
        padding: 0;
        margin: 0;
    }
    .usp-wrap {
        width: 96%;
        left: 2%;
    }
    .form-wrap .form-inline .form-group {
        padding: 20px 10px;
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        border-bottom: 2px solid #f2f2f2;
    }
    .form-wrap .form-inline .form-group .invalid-feedback {
        bottom: 5px;
        left: 43px;
    }
    .reg-entry {
        border-top: 2px solid #f2f2f2; 
        padding: 15px 10px;  
        text-align: right;
    }
    .reg-entry .btn-primary {
        max-width: 100px;
    }
    .feature-image h2 {
        padding: 110px 0 0;
    }
    .description-box {
        margin-bottom: 20px;
    }
    .footer-links {
        text-align: center;
    }
    .copyright {
        text-align: center;
        padding-top: 10px;
    }

    .carousel-caption h1 {
        font-size: 20px;
    }
    .carousel-caption h1 > span {
        font-size: 40px;
    }
    .renewal-form-wrap {
        height: auto;
        padding: 30px 0;
    }
    .renewal-form-inner {
        width: 90%;
        position: relative;
        top: auto;
        padding: 20px;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        /*padding: 15px 0;*/
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        margin-top: 0px;
    }
    .mkt-wrap .description-box {
        margin-bottom: 60px;
    }
    .container {
        width: 100%
    }
    .selectbox-wrap .municipality-section {
        margin-top: 0;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container {
        padding: 9px 0;
    }
    .selectbox-wrap #react-select-3-placeholder {
        padding: 17px 0;   
    }
    .header .navbar-toggle {
        display: block;
    }




    .header {
        display: block;
        padding: 9px 0;
    }
    .header .logo {
        float: left;
    }
    .header .navbar-toggle {
        float: right;
        border: 1px solid #072b7b;
        background-color: #072b7b;
        margin-top: 14px;
        margin-right: 0px;
    }
    .header .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .header .menu-links {
        display: none !important;
        background-color: rgba(7,43,123,1);
        border-top: 1px solid rgba(255,255,255,.2);
        top: 60px;
        position: absolute;
        left: -15px;
        width: calc(100% + 30px);
        right: 0;
        padding: 0;
    }
    .header .menu-links .navbar-collapse {
        padding: 0;
    }
    .header .menu-links.collapse.in {
        display: block !important;
    }
    .header .menu-links.collapse.in .mobile-nav {
        float: none !important;
    }
    .header .menu-links.collapse.in .mobile-nav ul {
        float: none !important;
        margin-top: 0;
    }
    .header .menu-links.collapse.in .mobile-nav ul li a {
        padding: 10px;
    }
    .header .menu-links.collapse.in .login-button {
        float: none !important;
        padding: 5px 10px 10px;
    }
    .header .menu-links.collapse.in .login-button button {
        width: 100%;
        float: none;
    }
    .header .nav.nav-pills {
        padding: 14px;
    }

    .header .nav.nav-pills {
        padding: 0 5px;
        margin: 8px 10px 0;
        border-radius: 5px;
    }
    .header .menu-links.collapse.in .nav.navbar-nav {
        float: none !important;
        margin: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li {
        padding: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        padding: 15px;
        border-bottom: 1px solid rgba(255,255,255,.2) !important;
        color: #0049ed;
    }
    .menu-links .nav li.dropdown .dropdown-menu {
        padding: 0;
    }
    .menu-links .nav li.dropdown .dropdown-menu li {
        padding: 0 !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        color: #666 !important;
        border-color: #e1e1e1 !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 59px;
    }
    .navbar-nav > li {
        float: none;
    }
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .analyse-box {
        margin-bottom: 20px;
    }
    .card-inner {
        width: 100%;
    }
    .property-detail-head-inner {
        background-position: bottom center;
        background-size: auto 430px;
    }
    .renewal-form-inner .usp-wrap ul li {
        padding: 0 10px;
        font-size: 16px;
    }
    .header .menu-links.collapse.in .login-button {
        text-align: center;
        padding: 15px;
    }
    .cart-link {
        margin-right: 10px;
    }
    .cart-link a {
        width: auto;
        background-color: rgba(255,255,255,.1);
        border-radius: 5px;
        margin: 0 7px;
        float: left;
    }
    .cart-link a:last-child {
        margin-right: 0;
    }
    .cart-link a span.badge {
        font-size: 14px;
        width: 18px;
        height: 18px;
        top: 3px;
        line-height: 19px;
    }
    .left-margin-move {
        margin-left: 0;
    }
    .year-detail .container,
    .property-detail-head-inner .container,
    .bill-detail-wrap .container {
        padding-left: 15px;
        padding-right: 15px;
    }
    .bill-detail-wrap .parcel-wrap {
        padding: 20px;
    }
    .header .navbar-toggle {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 12px;
    }
    .login-button {
        float: right;
        display: flex;
        align-content: flex-start;
        flex-direction: row;
    }
    .login-button .nav.nav-pills {
        margin-top: 0;
    }
    .login-button .cart-link {
        padding-top: 0;
    }
    .header .nav.nav-pills .dropdown .dropdown-toggle .glyphicon-user {
        background-color: transparent;
        margin-right: 0;
    }
    .logo img {
        height: 40px;
    }
    .header {
        height: 60px;
    }

    .header .menu-links.collapse.in .nav.navbar-nav li a {
        color: #fff !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        padding-left: 40px !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 52px;
    }
}
@media (max-width: 767px) {
    .header {
        position: relative;
    }
    .feature-image {
        height: auto;
    }
    .feature-image h2 {
        font-size: 32px;
        line-height: 40px;
        padding: 50px 0;
    }
    .form-wrap {
        position: relative;
        top: auto;
        bottom: auto;
    }
    .feature-image h2 span {
        font-size: 70px;
    }
    .form-wrap.form-wo-border .form-group .icon {
        top: 9px;
        float: left;
    }
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 0;
    }
    .form-wrap .row {
        padding: 0;
        margin: 0;
    }
    .usp-wrap {
        width: 90%;
        left: 5%;
        position: relative;
        bottom: auto;
        padding: 30px 0;
    }
    .usp-wrap ul li {
        line-height: 30px;
        padding: 30px 0;
        display: block;
    }
    .usp-wrap ul li br {
        display: none;
    }
    .usp-wrap ul li .svg-inline--fa {
        margin-top: -15px;
    }
    .form-wrap .form-inline .form-group {
        padding: 10px;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid #f2f2f2;
        margin-bottom: 0;
    }
    .form-wo-border .form-group .form-control {
        width: calc(100% - 15px);
        float: left;
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        border-bottom: 2px solid #f2f2f2;
    }
    .form-wrap .form-inline .form-group .invalid-feedback {
        bottom: 5px;
        left: 43px;
    }
    .reg-entry {
        border-top: none; 
        padding: 15px 10px;  
        text-align: right;
    }
    .reg-entry .btn-primary {
        max-width: 100px;
    }
    .description-box {
        margin-bottom: 20px;
    }

    .footer-links {
        text-align: center;
    }
    .copyright {
        text-align: center;
        padding-top: 10px;
    }
    .row {
        /*margin: 0;*/
    }
    .addr-m-padding .form-group {
        padding: 0;
    }
    .selected-state  .icon {
        top:4px !important;
        margin-right:5px;
    }


    .carousel-caption h1 {
        font-size: 20px;
    }
    .carousel-caption h1 > span {
        font-size: 40px;
    }
    .renewal-form-wrap {
        height: auto;
        padding: 30px 0;
    }
    .renewal-form-inner {
        width: 90%;
        position: relative;
        top: auto;
        padding: 20px;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        padding: 20px 0 15px;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        margin-top: 0px;
    }
    .mkt-wrap .description-box {
        margin-bottom: 60px;
    }
    .carousel-inner > .item img {
        min-height: 300px;
    }
    .renewal-form-inner .usp-wrap {
        margin: 0;
    }
    .renewal-form-inner .usp-wrap ul li {
        width: 100%;
    }
    .container {
        width: 100%
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        padding: 0;
        border-bottom: 2px solid #f2f2f2;
    }
    .selectbox-wrap .municipality-section {
        margin-top: 0;
        flex-direction: column-reverse;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container,
    .selectbox-wrap .municipality-section .selected-state {
        width: 100%;
    }
    .selectbox-wrap .municipality-section .selected-state {
        margin: 0;
        padding: 13px;
        border-right: 0;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container {
        border-top: 2px solid #f2f2f2;
    }
    .selectbox-wrap .municipality-section .selected-state .icon {
        top: 1px !important;
    }
    #react-select-5-placeholder.custom-select__placeholder {
        padding-top: 15px;
    }
    .css-1dimb5e-singleValue {
        /*padding: 15px 0;*/
    }
    .static-page-wrap {
        z-index: 0;
    }
    .static-page-wrap {
        background-color: #0049ed;
    }
    .static-page-wrap .wrap-img {
        display: none;
    }
    .static-page-wrap .inner-wrapper .white-wrapper {
        margin-bottom: 30px;
        padding: 20px;
    }
    .static-page-wrap .inner-wrapper h1 {
        font-size: 36px;
    }
    .price-wrapper {
        margin: 0 0 20px;
    }
    .contact-info {
        padding: 0 !important;
    }
    .step2-wrap {
        background-color: #0049ed;
        padding-bottom: 50px;
    }
    .step2-wrap .step2-wrap-img {
        display: none;
    }
    .step2 .form-wrapper {
        padding: 20px !important;
    }
    .header .nav.nav-pills {
        padding: 5px;
        margin: 8px 10px 0;
        border-radius: 5px;
    }
    .header .menu-links.collapse.in .nav.navbar-nav {
        float: none !important;
        margin: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li {
        padding: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        padding: 10px 10px;
        border-bottom: 1px solid #0049ed;
        color: #0049ed;
    }
    .menu-links .nav li.dropdown .dropdown-menu {
        padding: 0;
    }
    .menu-links .nav li.dropdown .dropdown-menu li {
        padding: 0 !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        color: #666 !important;
        border-color: #e1e1e1 !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 59px;
    }
    .analyse-box {
        margin-bottom: 20px;
    }
    .card-inner {
        width: 100%;
    }
    .tab-buttons .tab-button {
        width: 130px;
        height: 50px;
    }
    .react-tabs__tab {
        padding: 5px 10px !important;
        font-size: 16px;
        border-radius: 0px !important;
        margin: 0 !important;
    }
    
    .header {
        display: block;
        padding: 9px 0;
    }
    .header .logo {
        float: left;
    }
    .header .navbar-toggle {
        float: right;
        border: 1px solid #0049ed;
        background-color: #0049ed;
        margin-top: 14px;
        margin-right: 0px;
    }
    .header .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .header .menu-links {
        display: none !important;
        background-color: #fff;
        border-top: 1px solid #0049ed;
        top: 80px;
        position: absolute;
        left: -15px;
        width: calc(100% + 30px);
        right: 0;
        padding: 0;
    }
    .header .menu-links.collapse.in {
        display: block !important;
    }
    .header .menu-links.collapse.in .mobile-nav {
        float: none !important;
    }
    .header .menu-links.collapse.in .mobile-nav ul {
        float: none !important;
        margin-top: 0;
    }
    .header .menu-links.collapse.in .mobile-nav ul li a {
        padding: 10px;
        border-bottom: 1px solid #0049ed;
    }
    .header .menu-links.collapse.in .login-button {
        float: none !important;
        padding: 5px 10px 10px;
    }
    .header .menu-links.collapse.in .login-button button {
        width: 100%;
        float: none;
    }
    .home-search {
        padding-left: 10px;
        padding-right: 10px;
    }
    .home-search h2 {
        font-size: 18px;
    }
    .home-search .search-wrap {
        min-width: 350px;
    }
    .home-search .search-wrap .css-13cymwt-control .css-1fdsijx-ValueContainer,
    .home-search .search-wrap .css-t3ipsp-control .css-1fdsijx-ValueContainer {
        font-size: 14px;
    }
    .home-search .search-wrap.css-b62m3t-container .css-1nmdiq5-menu {
        border-radius: 20px;
        padding: 20px 0;
    }
    .home-search .search-wrap .css-13cymwt-control .css-1fdsijx-ValueContainer,
    .home-search .search-wrap .css-t3ipsp-control .css-1fdsijx-ValueContainer {
        max-width: 290px;   
    }
    .form-inline.row {
        margin: 0;
    }
    .form-inline.row .col-md-12.col-xs-12 {
        padding: 0;
    }
    .property-detail-head-inner {
        background-position: bottom center;
        background-size: auto 430px;
    }
    .renewal-form-inner .usp-wrap ul li {
        padding: 0 10px;
        font-size: 16px;
    }
    .header .menu-links.collapse.in .login-button {
        text-align: center;
        padding: 15px;
    }
    .cart-link a {
        width: 100%;
        background-color: rgba(255,255,255,.1);
        border-radius: 5px;
        margin-bottom: 10px;
    }
    .cart-link a:last-child {
        margin-right: 0;
    }
    .cart-link a span.badge {
        font-size: 16px;
        width: 26px;
        height: 26px;
        top: 7px;
        line-height: 26px;
    }

    .header .navbar-toggle {
        float: right;
        border: 1px solid #072b7b;
        background-color: #072b7b;
        margin-top: 14px;
        margin-right: 0px;
    }
    .header .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .header .menu-links {
        display: none !important;
        background-color: rgba(7,43,123,1);
        border-top: 1px solid rgba(255,255,255,.2);
        top: 80px;
        position: absolute;
        left: -15px;
        width: calc(100% + 30px);
        right: 0;
        padding: 0;
    }
    .header .menu-links .navbar-collapse {
        padding: 0;
    }
    .header .menu-links.collapse.in {
        display: block !important;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        padding: 15px;
        border-bottom: 1px solid rgba(255,255,255,.2) !important;
        color: #0049ed;
    }
    .content-popup {
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #ccc;
        box-shadow: 0px 0px 6px rgba(0,0,0,.2);
    }
    .content-popup .close-link {
        left: auto;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
    }
    .content-popup .close-link .svg-inline--fa {
        font-size: 24px;
        margin-top: 2px;
    }
    .content-popup .white-wrapper {
        margin-top: 70px;
        padding: 15px;
    }
    .bill-detail-wrap .white-wrapper {
        padding: 15px;
    }
    .left-margin-move {
        margin: 0 -15px;
    }
    .bill-detail-wrap .parcel-wrap {
        padding: 0;
        margin-top: 20px;
        background-color: #fff;
    }
    .year-detail .container, .property-detail-head-inner .container, .bill-detail-wrap .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .bill-detail-wrap .parcel-wrap .box-wrap {
        background-color: rgba(204,204,204,.3);
    }
    .footer {
        padding: 15px 0;
    }
    .home-search {
        padding: 120px 0 40px;
    }
    .mkt-wrap {
        padding-top: 0;
        padding-bottom: 0;
    }
    .payment-schedule {
        border: 0;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        margin-top: 10px;
    }
    .cart-total-wrap .submit-wrap .btn-primary {
        min-width: 100px;
    }
    .header .nav.nav-pills {
        padding: 0 5px;
        margin: 8px 10px 0;
        border-radius: 5px;
    }
    .login-button {
        float: none;
        display: block;
        align-content: inherit;
        flex-direction: inherit;
        float: left;
        padding-top: 10px;
        margin: 15px 0;
        width: 100%;
        border-top: 1px solid rgba(255,255,255,0.3);
    }
    .login-button .nav.nav-pills {
        margin-top: 0;
        margin-right: 0;
        padding: 4px 5px;
    }
    .login-button .cart-link {
        padding-top: 0;
    }
    .header .nav.nav-pills .dropdown .dropdown-toggle .glyphicon-user {
        background-color: transparent;
        margin-right: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .header .navbar-toggle {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 12px;
    }
    .cart-link {
        margin-right: 10px;
    }
    .cart-link a {
        width: auto;
        background-color: rgba(255,255,255,.1);
        border-radius: 5px;
        margin: 0 5px;
        float: left;
        font-size: 14px;
        padding: 0 10px;
        font-weight: 600;
    }
    .cart-link a:last-child {
        margin-right: 0;
    }
    .cart-link a span.badge {
        font-size: 14px;
        width: 18px;
        height: 18px;
        top: 0px;
        line-height: 19px;
        right: -1px;
    }
    .cart-link a span.glyphicon {
        font-size: 16px;
    }
    .logo img {
        height: 40px;
    }
    .header {
        height: auto;
    }
    .header .menu-links {
        top: 60px;
        z-index: 33;
    }
    .property-detail-head-inner {
        padding-top: 100px;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        color: #fff !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        padding-left: 40px !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 52px;
        right: -5px;
    }
    /********** header design start ***********/
    
    .property-detail-head .header-wrap .menu-icon {
        padding: 15px;
    }
    .property-detail-head .header-wrap .menu-icon svg {
        width: 35px;
        height: 35px;
    }
    .property-detail-head .header-wrap .brand-name img {
        height: 45px !important;
    }
    .property-detail-head .header-wrap .cart-menu {
        font-size: 16px;
    }
    .property-detail-head .header-wrap .cart-menu .glyphicon {
        font-size: 14px;
    }
    .property-detail-head .header-wrap .cart-menu .badge {
        font-size: 12px;
    }
    .drawer-wrap .drawer-menu .drawer-menu-link {
        font-size: 13px;
        padding: 15px;
    }
    .drawer-wrap .drawer-menu .drawer-menu-link .glyphicon {
        margin-right: 5px;
        font-size: 14px;
        margin-right: 10px;
    }
    .drawer-wrap .drawer-menu .drawer-menu-link .MuiListItemText-primary {
        font-size: 14px;
    }
    /********** header design end ***********/
    .content {
        margin-bottom: 0;
    }
    body {
        padding-bottom: 70px;
    }
    .bottom-navigation {
        background-color: #f9f9f9;
        box-shadow: 0px 0px 6px rgba(0,0,0,.1);
    }
    .desktop-view {
        display: none;
    }
    .mobile-view {
        display: block;
    }
    body {
        background-color: #f3f3f3;
    }
    .mobile-landing {
        padding: 15px;
        margin-top: 65px;
        margin-bottom: 0px;
    }
    .mobile-landing-inner {
        background-color: #fff;
        border-radius: 10px;
        margin: 0;
        padding: 0;
        height: calc(100vh - 125px);
    }
    .login-landing {
        padding: 0;
    }
    .login-landing-wrap {
        padding: 30px 15px;
        background-color: #fff;
    }
    .title-header-mobile {
        margin-bottom: 20px;
    }
    .title-header-mobile h1 {
        font-family: Montserrat,sans-serif;
        color: #333;
        font-size: 1.85em;
        font-weight: 500;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
    }
    .title-header-mobile h2 {
        font-family: Montserrat,sans-serif;
        color: #888;
        font-size: .95em;
        font-weight: 400;
        text-align: center;
        margin: 0 0 20px;
    }
    .property-detail-head header {
        box-shadow: none;
    }
    .login-landing-wrap .btn-primary {
        text-align: center;
        margin-bottom: 0;
        font-family: Montserrat,sans-serif;
    }
    .login-landing-wrap .btn-outline-primary {
        border-color: #16A34A;
        padding: 12px 20px;
        border-radius: 10px;
        border: 2px solid rgb(22,163,74);
        background-color: transparent;
        font-family: Montserrat,sans-serif;
        font-size: 18px;
        font-weight: 600;
        border-width: 2px;
        color: #16A34A;
        text-align: center;
        margin-bottom: 0;
    }
    .login-landing-wrap .btn-link {
        text-align: center;
        font-family: Montserrat,sans-serif;
        padding: 0;
        color: #999;
        margin: auto;
        border: none;
        padding: 5px 0;
        width: 100%;
        margin-top: 10px;
    }
    .login-landing-wrap .login-input input {
        font-family: Montserrat,sans-serif;
        border-color: #e1e1e1;
    }
    .white-card {
        background-color: #fff;

    }
    .heading-center {
        margin: 10px 0;
    }
    .heading-center h3 {
        /*font-family: "Ubuntu", sans-serif;*/
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
        color: #333;
        font-weight: 600;
        /*letter-spacing: .5px;*/
        text-align: center;
        position: relative;
        margin-bottom: 15px;
    }
    .heading-center h3 span {
        background-color: #f3f3f3;
        z-index: 1;
        position: relative;
        padding: 0 10px;
    }
    .heading-center h3:before {
        content: "";
        border-top: 1px solid #E6E6E6;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
    }
    .cart-list-card {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        position: relative;
        padding: 10px;
    }
    .cart-list-card .cart-info {
        font-family: "Montserrat", sans-serif;
        font-size: .95em;
        color: #666;
        margin: 0;
        flex: 1;
    }
    .cart-list-card .cart-info-items  {
        padding-bottom: 2px;
        padding-top: 2px;
        font-weight: 500;
    }
    .cart-list-card .cart-info-items span {
        color: #999;
    }
    .cart-list-card .cart-actions {
        font-family: "Montserrat", sans-serif;
        margin: 0;
        position: relative;
    }
    .cart-list-card .cart-actions .balance-info {
        font-size: 1.1em;
        color: #666;
        margin: 0;
        font-weight: 500;
        text-align: right;
    }
    .cart-list-card .cart-actions .action-links {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
    }
    .cart-list-card .cart-actions .action-links button {
        border-radius: 50%;
        border: 1px solid #0049ed;
        background-color: #fff;
        color: #0049ed;
        width: 30px;
        height: 30px;
        margin-left: 5px;
    }
    .payment-sch-mobile {
        background-color: #fff;
        border-radius: 5px;
        margin: 40px 0 0;
    }
    .btn-link-wrap {
        display: flex;
        gap: 10px;
    }
    .mobile-landing .btn-outline-primary {
        border-color: #16A34A;
        padding: 10px 20px;
        border-radius: 5px;
        border: 2px solid rgb(22,163,74);
        background-color: transparent;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-width: 2px;
        color: #16A34A;
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        flex: 1;
    }
    .payment-sch-wrap,
    .billing-info-wrap,
    .payment-details-wrap {
        background-color: #fff;
        padding: 10px;
        border-bottom: 0;
        border-radius: 0 0 5px 5px;
    }
    .white-head-mobile.heading-center h3 span {
        background-color: #ffff;
    }
    .payment-sch-wrap .form-control[disabled] {
        background-color: #f9f9f9;
        border: 1px solid #e1e1e1;
        color: #999;
    }
    .payment-sch-wrap .emi-opt {
        margin-bottom: 20px;
    }
    .payment-sch-wrap .btn-primary,
    .billing-info-wrap .btn-primary,
    .payment-details-wrap .btn-primary {
        border-color: #16A34A;
        padding: 10px 20px;
        border-radius: 5px;
        border: 2px solid rgb(22,163,74);
        background-color: #16A34A;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-width: 2px;
        color: #fff;
        margin: 10px 0;
    }
    .payment-sch-wrap .btn-width-full, 
    .billing-info-wrap .btn-width-full,
    .payment-details-wrap .btn-width-full  {
        width: 100%;
    }
    .billing-info-wrap .cart-payment-wrap {
        padding: 0;
    }
    .billing-info-wrap .cart-login-link {
        margin-top: 0;
    }
    .payment-sch-wrap .btn-secondary,
    .billing-info-wrap .btn-secondary,
    .payment-details-wrap .btn-secondary {
        background-color: transparent;
        border: none;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #999;
        margin: 10px 0;
    }
    .payment-wrap ul li {
        width: 140px;
        margin-bottom: 10px;
        background-size: contain !important;
    }
    .payment-wrap ul li:last-child {
        margin-right: 0;
    }
    .payment-details-wrap .form-cart .form-group {
        margin-bottom: 15px;
    }
    .payment-details-wrap .form-cart .form-group label {
        font-size: 14px;
        font-weight: bold;
        font-family: inherit;
    }
    .form-cart .form-group .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    }
    .logged-in-wrap {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        position: relative;
        padding: 10px;
        margin: 30px 0;
    }
    .logged-in-wrap h4 {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: #666;
        flex: 1;
        margin: 8px 0;
    }
    .logged-in-wrap .logout-button {
        border: 1px solid #0049ed;
        background-color: #fff;
        color: #0049ed;
        font-family: "Montserrat", sans-serif;
        padding: 0;
        line-height: 20px;
        border-radius: 5px;
        padding: 5px 10px;
    }
    #carousel-example-generic {
        display: none;
    }
    .renewal-form-wrap {
        padding-top: 66px;
    }
    .renewal-form-inner {
        width: 100%;
        border: 0;
        border-radius: 0;
        background-color: #fff;
    }
    .renewal-form-inner .form-wrap {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        color: #333 !important;
    }
    .renewal-form-inner .form-inline .custom-select__indicator,
    .renewal-form-inner .form-inline .custom-select__indicator svg {
        color: #333;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .css-13cymwt-control,
    .renewal-form-inner .form-wo-border .form-group .form-control,
    .renewal-form-inner .form-wo-border .form-group .icon {
        color: #333;
    }
    .renewal-form-inner .form-wo-border .form-group .form-control::-moz-placeholder {
        color: #333;
    }
    .renewal-form-inner .form-wo-border .form-group .form-control:-ms-input-placeholder {
        color: #333;
    }
    .renewal-form-inner .form-wo-border .form-group .form-control::-webkit-input-placeholder {
        color: #333;
    }
    .renewal-form-inner .form-wrap .form-inline .form-group {
        padding: 10px;
        margin: 0 0 10px 0 !important;
        background-color: #fff;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        border-radius: 5px;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        padding: 18px 0 15px;
    }

    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        margin-top: 15px;
        background-color: #fff;
        border-radius: 0 0 10px 10px;
    }
    .renewal-form-inner .form-wo-border .btn-primary {
        background: #16A34A;
        border: 2px solid rgb(22,163,74);
        width: 100%;
        max-width: 100%;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-width: 2px;
        color: #fff;
        padding: 10px 20px !important;
    }
    .renewal-form-inner .reg-entry {
        padding: 0 0 10px 0;
    }
    .renewal-form-inner .usp-wrap {
        background-color: #fff;
        border-radius: 5px;
        padding: 0;
        margin-top: 0px;
    }
    .renewal-form-inner .usp-wrap ul li {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        border-bottom: 1px solid #e1e1e1;
        padding: 20px 0;
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        line-height: 20px;
    }
    .renewal-form-inner .usp-wrap ul li:last-child {
        border-bottom: 0;
    }
    .renewal-form-inner .usp-wrap ul li .svg-inline--fa {
        padding: 10px ;
        margin: 0 10px 0 0 !important;
    }
    .mkt-wrap .pt-40,
    .mkt-wrap .pb-40 {
        padding: 0;
    }
    .mkt-wrap h2 > span {
        font-family: Montserrat,sans-serif;
        font-weight: 600;
        font-size: 16px;
    }
    .mkt-wrap h2 {
        margin-top: 0;
        font-family: Montserrat,sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
    }
    .mkt-wrap .description-box {
        background-color: #fff;
    }
    .mkt-wrap .description-box h3 {
        font-family: Montserrat,sans-serif;
        font-size: 16px;
        font-weight: 600;
    }
    .mkt-wrap .description-box p {
        font-family: Montserrat,sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .selectbox-wrap .municipality-section .selected-state .icon {
        background-color: #333;
        color: #fff;
    }
    .selectbox-wrap .municipality-section .selected-state .selected-state-label {
        color: #333;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container {
        border-color: #f1f1f1;
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        padding: 0 !important;
    }

    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        background-color: #fff;
        width: 100%;
        padding: 0;
        margin-top: -2px;
        color: #333;
    } 
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu > div > div {
        color: #333;
    }
    .renewal-form-wrap .municipality-section .custom-select__single-value {
        color: #333 !important;
        padding: 15px 0;
    }
    .renewal-form-inner .usp-wrap ul li:nth-child(2n) .svg-inline--fa {
        background: #939598;
    }
    .renewal-form-head h2 {
        color: #666;
        text-transform: uppercase;
        text-align: center;
        margin-top: 0;
        font-family: Montserrat,sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 5px;
        padding-top: 10px;
    }
    .content-popup {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
        background-color: #f3f3f3;
        box-shadow: none;
        border: none;
    }
    .close-content-div {
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #edecec;
        color: #fff;
        display: flex;
        min-height: 57px;
        padding: 0 15px;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0;
        z-index: 1111;
    }
    .content-popup .white-wrapper {
        box-shadow: none;
    }
    .content-popup .close-link {
        background-color: #f2f3f4;
        color: #999;
        width: 35px;
        height: 35px;
    }
    .content-popup .close-link .svg-inline--fa {
        padding: 4px;
        margin-top: 1px;
    }
    .cart-actions .order-placed {
        color: #fff;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;
        background-color: green;
        border-radius: 13px;
        padding: 5px 5px;
        text-align: center;
        margin-top: 3px;
    }
}


/* Hide .footer-mobile on desktop (screens wider than 768px) */
@media (min-width: 768px) {
    .footer-mobile {
        display: none;
    }
}

/* Hide .footer on mobile (screens smaller than 768px) */
@media (max-width: 767px) {
    .footer {
        display: none;
    }
}
.MuiBadge-root {
    position: unset !important;
}
