/*.container {
    margin-top: 80px;
}*/
.page-wrap.step2 {
    padding-top: 50px; 
    position: ;   
}
.step2-wrap {
    position: relative;
}
.step2-wrap .step2-wrap-img {
    background: url('/public/images/step2-back.jpg') no-repeat;
    background-size: cover;
    min-height: 600px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-position: top -80px left 0;
}
.steps-indicator {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: center;
    align-items: flex-start;
    /*width: 60%;*/
    margin: auto;
    margin-bottom:80px;
}
.step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /*padding: 0 50px;*/
    width: 200px;
}
.step-icon {
    display: flex;
    align-items: center;
}
.step-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #20be5a;
    margin-bottom: 8px;
    background-color: #fff;
}
.completed .step-circle {
    background-color: #fff;
    border-color: #fff;
}
.completed-icon, .remaining-icon {
    color: #0049ed;
}
.remaining-icon {
    color: #20be5a;
}
.step-number {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.step-text {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
}
.step-line {
    /*flex: 1;*/
    height: 2px;
    position: relative;
    top:  13px;
    border-top: 2px solid #fff;
    width: 70px;
}
.step-line:after {
    content: "\e072";
    font-family: "Glyphicons Halflings";
    position: absolute;
    top: -12px;
    right: -10px;
    color: #fff;
}

.step-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    margin-bottom: 5px;
}
.completed {
    color: #5cb85c;
}
.accomplished .step-text {
    color: #fff;
}
.step2 .banner {
    
}
.step2 .banner h2 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #fff;
    text-align: left;
    font-size: 50px;
    padding-left: 30px;
    padding-top: 20px;
}
.step2 .form-wrapper {
    margin: 0;
    background-color: #fff;
    border-radius: 30px;
    padding: 30px;
    box-shadow: 5px 5px 9px rgba(0,0,0,.2);
}
.step2 .form-wrapper .form-group label {
    font-family: 'Poppins', sans-serif;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}
.step2 .form-wrapper .form-group .css-b62m3t-container {
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
}
.step2 .form-wrapper .form-group .css-b62m3t-container .form-select__control {
    /*border-color: #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;*/
    background-color: transparent;
    border: none;
}
.step2 .form-wrapper .form-group .css-b62m3t-container .form-select__control:hover {
    border-color: #ebf1ff;
}
.step2 .form-wrapper .form-group .css-b62m3t-container .form-select__control .form-select__value-container {
    padding: 8px 10px;
}
.step2 .form-wrapper .form-group .css-b62m3t-container .form-select__control .form-select__indicators .form-select__indicator-separator {
    display: none;
}
.step2 .selected-state .selected-state-label {
    color: #666;   
}
.step2 .form-wrapper .form-group .form-control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Poppins', sans-serif;
}
.step2 .form-wrapper .form-group .form-control::-moz-placeholder {
    color: #666;
    opacity: 1;
}
.step2 .form-wrapper .form-group .form-control:-ms-input-placeholder {
    color: #666;
}
.step2 .form-wrapper .form-group .form-control::-webkit-input-placeholder {
    color: #666;
}
.step2 .form-wrapper .form-group .react-datepicker-wrapper .form-control:hover,
.step2 .form-wrapper .form-group .react-datepicker-wrapper .form-control:visited {
    background-color: #fff;
    border-color: #0049ed;
    border-width: 2px;
}
.form-wrapper .form-group .form-select__value-container {
    padding: 12px 10px !important;
}
.form-wrapper .form-group .form-select__value-container .form-select__single-value {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
    color: #333 !important;
}
.form-wrapper .form-group .form-select__placeholder {
    font-size: 18px;
    color: #666;
    font-family: 'Poppins', sans-serif;
}   
.label-bold {
    font-weight: bold;
}
.invalid-feedback {
    color: #dc3545;
    font-family: 'Poppins', sans-serif;
}
.plate-list {
    margin-top: 20px;
    padding:20px;
}
.plate-list h3 {
    font-size: 18px;
    font-weight: bold;
}
.plate-list ul {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}
.plate-list li {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 10px 0;
}
.text-right {
    text-align: right;
}
/* Adjust calendar icon position */
.input-group-append .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* Adjust error message styles */
.invalid-feedback {
    display: block;
    color: #dc3545;
    margin-top: 5px;
}
.react-datepicker-wrapper {
    width:100%; 
}
.page-wrap {
    /*margin-top: 50px;*/
    padding-bottom: 50px;
}
/************ date picker start ***************/
.react-datepicker-popper .react-datepicker {
    background-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #0049ed;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #0049ed;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #0049ed;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    border-top-color: #0049ed;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border-top-color: #0049ed;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container {
    background-color: #fff;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header {
    background-color: #0049ed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.react-datepicker__navigation-icon--next {
    left: 2px;
}
.react-datepicker__navigation-icon {
    top: 5px;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
    border-color: #fff;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    line-height: 2.3rem;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    font-weight: 700;
}
.react-datepicker {
    font-family: 'Inter', sans-serif;
    border-radius: 9px;
    border-color: #0049ed;
}
.react-datepicker__day-names, .react-datepicker__week .react-datepicker__day {
    font-size: 1.3rem;
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
}
.react-datepicker__day-names, .react-datepicker__week .react-datepicker__day.react-datepicker__day--outside-month {
    color: #ccc;
}
.react-datepicker__day-names, .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
    background-color: #0049ed;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle { 
    margin-left: -20px;
}
/************ date picker end ***************/
.step2 .form-wrapper .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    border-width: 2px;
}
.step2 .form-wrapper .btn-primary .fa-plus {
    font-size: 16px;
    padding-right: 5px;
}
.step2 .form-wrapper .btn-bordered {
    border-color: #818284;
    color: #818284;
    background: transparent;
    border-width: 2px;
}
.plate-list {
    border-top: 1px solid #d3d3d3;
    margin-top: 40px;
    padding: 30px 0 0;
}
.plate-list h3 {
    margin: 0;
    padding-bottom: 5px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    border: none;
}
.plate-list ul {
    border: none;
    box-shadow: 1px 2px 5px rgba(233, 238, 255);
    margin-bottom: 20px;
}
.plate-list ul li {
    border: none;
    background-color: #fff;
    border: 1px solid #e9eeff;
    padding: 10px 15px;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #333;
}
.plate-list ul li:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.plate-list ul li:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.plate-list ul li table {
    margin: 0;
}
.plateDel {
    text-align: right;
}
.plateDel button .fa-trash {
    color: #0049ed;
    font-size: 16px;
}
.signup-wizard-img {
    margin-top: 30px;
    width: 100%;
}

.address-wrap {
    border-top: 1px solid #d3d3d3;
    margin-top: 40px;
    padding: 30px 0 0;
}

.address-wrap h3 {
    margin: 0;
    padding-bottom: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    border: none;
    text-align: left;
}
.payment-wrap ul {
    margin: 15px 0;
    padding: 0;
    float: left;
    width: both;
}
.payment-wrap ul li {
    display: inline-block;
    padding: 0;
    border: 2px solid #e0eafd;
    width: 180px;
    height: 60px;
    border-radius: 10px;
    margin-right: 20px;
    position: relative;
    float: left;
}
.payment-wrap ul li.strip {
    background: url('/public/images/stripe-grey.png') center center no-repeat;
}
.payment-wrap ul li.card {
    background: url('/public/images/card-grey.png') center center no-repeat;
}
.payment-wrap ul li.active {
    border-color: #57be5b;
}
.payment-wrap ul li.strip.active {
    background: url('/public/images/stripe-blue.png') center center no-repeat;
}
.payment-wrap ul li.card.active {
    background: url('/public/images/card-blue.png') center center no-repeat;
}
.payment-wrap ul li .fa-check {
    position: absolute;
    border: 2px solid #57be5b;
    border-radius: 50px;
    color: #57be5b;
    width: 20px;
    height: 20px;
    padding: 5px;
    top: -15px;
    right: -15px;
    background-color: #fff;
    display: none;
}
.payment-wrap ul li.active .fa-check {
    display: block;
}
.error {
    color: #dc3545;
    margin: 0;
    font-size: 16px;
    text-align: left;
    font-family: 'Inter', sans-serif;
}
.count-wrap-div .count-inner {
    padding: 0;
    border: 1px solid #dadada;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0,0,0,.1);
}
.count-wrap-div .count-inner div {
    background-color: transparent;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.count-wrap-div .count-inner div:first-child {
    margin-top: 5px;
}
.count-wrap-div .count-inner div:hover {
    background-color: #0c4adb;
    color: #fff;
}
.account-page-wrap {
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    width: 100%;
    margin: auto;
}
.account-page-wrap .heading {
    margin: 0;
    padding: 20px;
    width: 100%;
    float: left;
}
.account-page-wrap .heading h2 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #333;
    text-align: left;
    font-size: 32px;
    float: left;
    line-height: 55px;
}
.account-page-wrap table,
.table-wrap table {
    margin: 0;
}
.account-page-wrap table thead th,
.table-wrap table thead th {
    background-color: #e1e1e1;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding: 20px;
    border: none;
}
.account-page-wrap table tbody tr:hover,
.table-wrap table tbody tr:hover {
    background-color: #e6f8ee;
}
.account-page-wrap table tbody td,
.table-wrap table tbody td {
    padding: 15px 20px;
    border: none;
}
.account-page-wrap table tbody td.content,
.table-wrap table tbody td.content {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 30px;
}
.account-page-wrap table tbody tr:nth-child(odd) td,
.table-wrap table tbody tr:nth-child(odd) td {
    background-color: #fff;
}
.account-page-wrap table tbody tr:nth-child(even) td,
.table-wrap table tbody tr:nth-child(even) td {
    background-color: #f9f9f9;
}
.account-page-wrap .btn-primary,
.table-wrap .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
    float: right;
}
.account-page-wrap .btn-primary .fa-plus,
.table-wrap .btn-primary .fa-plus {
    font-size: 16px;
    padding-right: 5px;
}
.account-page-wrap .btn-bordered,
.table-wrap .btn-bordered {
    border-color: #16A34A;
    color: #16A34A;
    background: transparent;
    border-width: 2px;
}

.page-wrap .account-page-wrap .btn-primary:active:hover,
.page-wrap .account-page-wrap .btn-primary.active:hover,
.page-wrap .account-page-wrap .btn-primary:active:focus,
.page-wrap .account-page-wrap .btn-primary.active:focus,
.page-wrap .account-page-wrap .btn-primary:active.focus,
.page-wrap .account-page-wrap .btn-primary.active.focus {
  background-color: #16A34A;
  border-color: #16A34A;
  color: #fff;
}

.step2 .custom-select__single-value {
    color: #666 !important;
}

/* Style the state-section */
.step2 .state-section {
    color: #000 !important;
  /* Add your custom styles here */
}

/* Style the municipality-section */
.step2 .municipality-section {
    color: #666 !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix */
.step2 .custom-select__control {
    color: #666 !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for focused elements */
.step2 .custom-select__control--is-focused {
    color: #666 !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for selected values */
.step2 .custom-select__single-value {
    color: #666 !important;
  /* Add your custom styles here */
}
.cust-field-wrap > div {
    background-color: #fff;
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px !important;
    padding: 20px;
    margin-bottom: 20px;
}
.cust-field-wrap > div h4 {
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
    margin: 0 0 20px;
}
.submit-wrap {
    display: flex;
    flex-direction: row;
    gap: 10px; 
}
.submit-wrap .btn {
    flex-grow: 1;
}

.welcomeText {
    margin-right:15px;
}
/* Define a CSS class for the SweetAlert title */
.sweet-title {
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #333; /* Customize the text color */
}

/* Define a CSS class for the SweetAlert content */

.swal2-html-container{
    font-size: 18px !important; /* Adjust the font size as needed */
    color: #555 !important; /* Customize the text color */
    line-height: 30px !important;
}
.swal2-confirm {
    font-size: 18px !important;
    background-color: #16A34A !important;
}
.swal2-cancel {
    font-size: 18px !important;   
}
.form-select__control .form-select__value-container .form-select__multi-value {
    background-color: #0049ed;
}
.form-select__control .form-select__value-container .form-select__multi-value .form-select__multi-value__label {
    color: #fff;
}
.form-select__control .form-select__value-container .form-select__multi-value .form-select__multi-value__remove {
    color: #fff;
}
.form-select__control .form-select__value-container .form-select__multi-value .form-select__multi-value__remove:hover {
    background-color: #fff;
    color: #0049ed;
    border-radius: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .payment-wrap ul li {
        width: 175px;
    }
    .payment-wrap ul li:last-child {
        margin-right: 0;
    }
    .steps-indicator {
        width: 80%;
    }
}
@media (min-width: 768px) and (max-width: 991px) { 
    .account-page-wrap {
        width: 100%;
    }
    .steps-indicator {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .page-wrap.step2 {
        padding: 0;
    }
    .step2 .banner {
        margin-bottom: 20px;
        padding: 0;
        background-color: transparent;
    }
    .step2 .banner .text-center {
        display: none;
    }
    .account-page-wrap {
        width: 100%;
    }
    .payment-wrap ul li {
        width: 110px;
        margin-bottom: 10px;
        background-size: contain !important;
    }
    .payment-wrap ul li:last-child {
        margin-right: 0;
    }
    .account-page-wrap .heading h2 { 
        float: none;
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 10px;
    }
    .account-page-wrap .btn-primary {
        float: none;
        padding: 8px 15px;
        font-size: 16px;
    }
    .steps-indicator {
        display: none;
    }
    .step2 .banner h2 {
        font-size: 30px;
        padding-left: 10px;
    }
    .step2 .form-wrapper {
        margin-bottom: 50px;
    }
    .submit-wrap {
        display: inline-block;
    }
    .submit-wrap .btn {
        margin-bottom: 10px;    
        width: 100%;
    }
    .page-wrap.step2 .form-wrapper .addr-m-padding {
        margin: 0;
    }
}