@media screen and (min-width: 991px) {
  .welcome-page .section-1 {
    width: 60%;
  }
}

.welcome-page .section-1 {
  margin: 0 auto;
  margin-top: 3rem;
}

.welcome-page .section-1 .u-text-1 {
  font-weight: 400;
  margin: 41px auto 0;
  font-size: 2rem;
  line-height: 2rem;
}

.welcome-page .section-1 p {
  width: 70%;
  margin: 0 auto;
}

.welcome-page .section-2 {
  margin: 0 auto;
  width: 80%;
  margin-top: 3rem;
}

.welcome-page .section-2 svg {
  max-width: 500px;
}

.welcome-page .section-2 .b-r {
  border-right: 1px solid;
}

.welcome-page .section-2 .b-l {
  border-left: 1px solid;
}
