.custom-stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0049ed;
    border-radius: 5px 5px 0 0;
    margin-top: 30px;
    padding: 15px 10px;
}
.step {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex: 1;
}
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 600;
    font-family: Montserrat,sans-serif;
    font-size: 18px;
    line-height: 16px;
    text-transform: uppercase;
}
.step.complete .circle {
    background-color: rgba(255,255,255,.1);
}
.step.active .circle {
    background-color: #2196f3;
}
.circle span {
    display: block;
    font-size: 11px;
    color: rgba(255,255,255,.5);
}
.line {
    display: none;
}
.step.complete .line {
    background-color: #4caf50;
}
.label {
    margin-top: 5px;
    font-size: 12px;
    font-family: Montserrat,sans-serif;
    text-transform: uppercase;
}
.active-label {
    font-weight: bold;
    color: #2196f3;
}


@media (min-width: 992px) and (max-width: 2500px) {
    .steps-wrap .custom-stepper {
        padding: 0;
    }
    .steps-wrap .custom-stepper .step {
        flex-direction: row;
        padding: 25px;
        position: relative;
    }
    .steps-wrap .custom-stepper .step:first-child {
        background-color: rgba(255,255,255,.1);
    }
    .steps-wrap .custom-stepper .step:nth-child(2) {
        background-color: rgba(255,255,255,.15);
    }
    .steps-wrap .custom-stepper .step:last-child {
        background-color: rgba(255,255,255,.2);
    }
    .steps-wrap .custom-stepper .step .circle {
        padding: 15px;
        font-size: 24px;
        font-family: "Roboto", sans-serif;
    }
    .steps-wrap .custom-stepper .step .circle span {
        margin-right: 5px;
        font-size: 18px;
        font-weight: 600;
        display: none;
    }
    .steps-wrap .custom-stepper .step.active:after {
        content: "";
        width: 0;
        height: 0;
        border-top: 14px solid #0049ed;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        position: absolute;
        bottom: -14px;
        left: 50%;
        margin-left: -7px;
    }
    .steps-wrap .custom-stepper .step.active .circle {
        color: rgba(255,255,255,.4);
    }
    .steps-wrap .custom-stepper .step .label {
        font-size: 24px;
        font-family: "Roboto", sans-serif;
    }
    .steps-wrap .payment-sch-wrap,
    .steps-wrap .billing-info-wrap,
    .steps-wrap .payment-details-wrap {
        background-color: #fff;
        padding: 20px;
    }
    .steps-wrap .billing-info-wrap .cart-login-link {
        margin-top: 0;
    }
    .steps-wrap .payment-sch-wrap h3,
    .steps-wrap .billing-info-wrap h3,
    .steps-wrap .payment-details-wrap h3 {
        margin-bottom: 30px;
        font-family: "Roboto", sans-serif;
    }
    .steps-wrap .payment-sch-wrap .btn-primary,
    .steps-wrap .billing-info-wrap .btn-primary,
    .steps-wrap .payment-details-wrap .btn-primary {
        margin-top: 20px;
        background-color: #0049ed;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 30px;
        border-radius: 50px;
        border: none;
    }
    .steps-wrap .payment-sch-wrap .btn-primary .svg-inline--fa,
    .steps-wrap .billing-info-wrap .btn-primary .svg-inline--fa,
    .steps-wrap .payment-details-wrap .btn-primary .svg-inline--fa {
        margin-left: 10px;
    }
    .steps-wrap .payment-sch-wrap .btn-secondary,
    .steps-wrap .billing-info-wrap .btn-secondary,
    .steps-wrap .payment-details-wrap .btn-secondary {
        margin-top: 20px;
        background-color: transparent;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 30px;
        border: none;
        color: #999;
    }
    .steps-wrap .payment-sch-wrap .form-group .form-control[disabled],
    .steps-wrap .billing-info-wrap .form-group .form-control[disabled],
    .steps-wrap .payment-details-wrap .form-group .form-control[disabled] {
        background-color: #e1e1e1;
        border: 1px solid #e1e1e1;
        color: #999;
    }
    .steps-wrap .payment-sch-wrap .form-group .form-control,
    .steps-wrap .billing-info-wrap .form-group .form-control,
    .steps-wrap .payment-details-wrap .form-group .form-control {
        background-color: #fff;
        border: 1px solid #cccccc;
        color: #555;
        height: 45px;
        border-radius: 10px;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
    }
    .logged-in-wrap {
        background-color: #fff;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        position: relative;
        padding: 10px;
        margin: 30px 0;
    }
    .logged-in-wrap h4 {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #666;
        flex: 1;
        margin: 8px 0;
        padding-left: 10px;
    }
    .logged-in-wrap .logout-button {
        border: 1px solid #0049ed;
        background-color: #fff;
        color: #0049ed;
        font-family: "Roboto", sans-serif;
        padding: 0;
        line-height: 20px;
        border-radius: 5px;
        padding: 5px 10px;
    }
    .steps-wrap .custom-stepper .step .circle span.tick {
        display: block;
        font-size: 30px;
    }
}